import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'data/services/config';

import { OauthConfiguration } from './oauthConfigurationTypes';

const NAME = 'oauthConfigurationSlice';

export const fetchOauthConfiguration = createAsyncThunk(
  `${NAME}/fetch`,
  async (_, { rejectWithValue }) => {
    try {
      return await get('/sessions/oauth_configuration');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: OauthConfiguration = {
  enabled: false,
  idps: [],
};

const oauthConfigurationSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOauthConfiguration.fulfilled, (state, action) => {
      state.enabled = action.payload.enabled;
      state.idps = action.payload.idps;
    });
  },
  selectors: {
    enabled: (state) => state.enabled,
    idps: (state) => state.idps,
  },
});

export const fromOauthConfiguration = oauthConfigurationSlice.selectors;

export default oauthConfigurationSlice.reducer;
