import { getEnvVar } from 'config';
import { declineInvitation } from 'data/actions/judgeInvitations';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { useState } from 'react';
import { Button, Modal } from '@valid-eval/shared-react-components';
import { existingJudgeSignUpURL } from '../urls';

const DeclineActionButton = ({ notification: { data, ...notification } }) => {
  const [isOpened, setIsOpened] = useState();
  const { eventId, invitation_id: id } = data;

  const toggleModal = (event) => {
    event.preventDefault();
    setIsOpened(true);
  };

  const handleConfirm = async () => {
    const { error } = await window.store.dispatch(declineInvitation(eventId, id, notification.id));
    if (error)
      window.store.dispatch(
        notifyError(
          `Failed to decline invitation, please contact ${getEnvVar(
            'REACT_APP_SUPPORT_EMAIL',
            'support@valideval.com',
          )} for assistance`,
          'Error',
        ),
      );
    else window.store.dispatch(notifySuccess('The invitation has been declined', 'Success'));
  };

  return (
    <>
      <Modal show={isOpened}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please confirm you want to decline this invitation.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" id={'decline-modal-confirmation-cancel'} onClick={toggleModal}>
            Cancel
          </Button>
          <Button
            variant="success"
            id={'decline-modal-confirmation-confirm'}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        variant={'link'}
        className="notification-action-button"
        style={{ float: 'right' }}
        onClick={toggleModal}
      >
        Decline
      </Button>
    </>
  );
};

export const getActionButtons = (notification) => [
  {
    title: 'Go to sign up',
    type: 'success',
    url: existingJudgeSignUpURL(
      notification.id,
      notification.data.event_id,
      notification.data.email,
      notification.data.invitation_id,
    ),
  },
  () => <DeclineActionButton notification={notification} />,
];

export const getText = (notification) =>
  `Sign up to be a judge for the ${notification.event} cohort!`;

export const displayName = 'Send sign-up reminder';
