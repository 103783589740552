import React, { useState } from 'react';
import { Button, Modal, Form } from '@valid-eval/shared-react-components';
import { useTranslation } from 'react-i18next';

const CancelNotificationModal = ({ show, onCancel, onConfirmDelete }) => {
  const { t } = useTranslation();
  const [deleteForAll, setDeleteForAll] = useState(false);

  const handledeleteForAllChange = (e) => {
    setDeleteForAll(e.target.checked);
  };

  const handleConfirmClick = () => {
    onConfirmDelete(deleteForAll);
  };

  return (
    <Modal id="cancel-notification-modal" show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{t('notifications.modal_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('notifications.cancel_confirmation')}</p>
        <Form.Check
          id="delete-for-all-teams-checkbox"
          type="checkbox"
          onChange={handledeleteForAllChange}
          checked={deleteForAll}
          label={t('notifications.delete_for_all_records')}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" id="cancel-notification-modal-close" onClick={onCancel}>
          {t('notifications.close_button')}
        </Button>
        <Button
          variant="danger"
          id="cancel-notification-modal-confirm"
          onClick={handleConfirmClick}
        >
          {t('notifications.cancel_notification')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelNotificationModal;
