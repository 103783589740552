import { Alert } from '@valid-eval/shared-react-components';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { fromContents } from 'data/features/contents';
import withRouter from 'routes/withRouter';
import { getFullDateHumanReadable } from 'utils';

import { StepComponentProps } from '../types';
import StepContainer from './StepContainer';

type OwnProps = StepComponentProps;

const mapStateToProps = (state: any, ownProps: OwnProps) => {
  const { event } = ownProps;
  return {
    title: fromContents.getContent(state.toJS(), event?.id || '', 'Team', 'Signup', 'Closed Title'),
    notice: fromContents.getContent(
      state.toJS(),
      event?.id || '',
      'Team',
      'Signup',
      'Closed Notice',
    ),
  };
};

const connector = connect(mapStateToProps, null);

type DisabledStepProps = OwnProps & ConnectedProps<typeof connector>;

const DisabledStep = ({ title, notice, event }: DisabledStepProps) => {
  const { t } = useTranslation();

  const teamSignupOpen = event?.team_signup_open
    ? getFullDateHumanReadable(event.team_signup_open)
    : null;

  return (
    <StepContainer
      content={
        <>
          <Alert
            variant="warning"
            className="py-3 d-flex align-items-center justify-content-center"
          >
            <i className="fa-sharp fa-solid fa-circle-exclamation me-2 text-orange01" />
            <span
              dangerouslySetInnerHTML={{
                __html: event?.team_signup_will_open
                  ? t('auth.teams_sign_up.steps.disabled.notice_will_open', {
                      date: teamSignupOpen,
                    })
                  : t('auth.teams_sign_up.steps.disabled.notice'),
              }}
            />
          </Alert>
          <h1>{parse(title?.content || '')}</h1>
          <div>{parse(notice?.content || '')}</div>
        </>
      }
    />
  );
};

export default withRouter(connector(DisabledStep));
