import { teamNav } from '../urls';

export const displayName = 'Reject application';

export const getActionButtons = (notification) => [
  {
    title: `Review results report`,
    url: teamNav.results(notification.data.event_id, notification.data.team_id),
    toggleSeen: true,
  },
];

export const getText = (notification) =>
  `We've looked at your application to ${notification.event} and concluded that it fails to
  satisfy basic eligibility criteria. We regret to inform you that your application has
  therefore been rejected and will not be evaluated further.`;
