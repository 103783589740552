import { Note as NoteType } from 'data/features/notesTypes';
import Note from './components/Note';
import { Fragment } from 'react';

type NotesListProps = {
  compact?: boolean;
  emptyLabel?: string;
  isOrganizerScreen?: boolean;
  notes: NoteType[];
  phaseId: string;
  showDimensionName?: boolean;
  teamId: string;
  unredacted?: boolean;
};

/**
 * Returns the dimension header for a note.
 * If the note is the first of its dimension, it returns the dimension name.
 * Otherwise, it returns null.
 */
const getDimensionHeader = (note: NoteType, displayedDimensions: Set<string>): string | null => {
  const dimensionName = note.dimensionName || 'Overall Comments';
  const isFirst = !displayedDimensions.has(dimensionName);
  displayedDimensions.add(dimensionName);
  return isFirst ? dimensionName : null;
};

const NotesList = ({
  compact,
  emptyLabel = 'None.',
  isOrganizerScreen,
  notes,
  phaseId,
  showDimensionName,
  teamId,
  unredacted,
}: NotesListProps) => {
  const firstInternalNote = notes.findIndex((note) => note.type === 'internal');
  const displayedDimensions = new Set<string>();

  if (!notes?.length) return <small>{emptyLabel}</small>;

  return notes.map((note, index) => {
    const dimensionHeader = getDimensionHeader(note, displayedDimensions);

    return (
      <Fragment key={note.id}>
        {dimensionHeader && <h2 className="d-none d-print-block">{dimensionHeader}</h2>}
        <Note
          note={note}
          isFirstInternal={index === firstInternalNote}
          isOrganizerScreen={isOrganizerScreen}
          phaseId={phaseId}
          teamId={teamId}
          compact={compact}
          showDimensionName={showDimensionName}
          unredacted={unredacted}
        />
      </Fragment>
    );
  });
};

export default NotesList;
