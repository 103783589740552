import { organizerNav } from '../urls';

export const getActionButtons = (notification) => [
  {
    title: 'View Team Profile',
    url: organizerNav.teamProfile(notification.data.event_id, notification.data.team_profile_id),
    toggleSeen: true,
  },
];

export const getText = (notification) =>
  `The primary contact for team "${notification.data.team_name}" has been changed to ${notification.data.primary_contact_name}.`;
