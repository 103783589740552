import { Navbar } from '@valid-eval/shared-react-components';
import { logout } from 'data/actions/users';
import { getCurrentUser, getCurrentUserProfiles } from 'data/reducers';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import IEWarningModal from '../../../components/IEWarningModal';
import Image from '../../../components/Image';
import AuthorizedNav from './AuthorizedNav';
import Styles from './index.module.scss';
import UnauthorizedNav from './UnauthorizedNav';

const IEWarningShown = localStorage.getItem('IEWarningShown');

const MainMenu = ({ user, profiles, logout, showAsGuest }) => {
  const [showModal, setShowModal] = useState(false);

  const handleToggleShowModal = () => {
    setShowModal(!showModal);
  };

  const navigate = useNavigate();
  const url = user ? '/app/dashboard' : '/';
  const authorized = user && !showAsGuest;
  const IEWarningValue = IEWarningShown ? 'first_login' : 'first_render';

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  return (
    <>
      {authorized && <IEWarningModal newValue={IEWarningValue} />}
      <Navbar
        className={Styles.NavContainer}
        collapseOnSelect
        expand="md"
        sticky="top"
        variant="dark"
      >
        <div className={Styles.NavMobile}>
          <Navbar.Brand>
            <Link to={url}>
              <Image
                className={Styles.LogoNav}
                src="/images/ve-hrztl-light-a.svg"
                alt="Valid Eval"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </div>
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          {authorized ? (
            <AuthorizedNav user={user} profiles={profiles} logout={handleLogout} />
          ) : (
            <UnauthorizedNav showModal={showModal} onShowModal={handleToggleShowModal} />
          )}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

MainMenu.displayName = 'MainMenu';

export default connect(
  (state) => {
    const user = getCurrentUser(state);
    const profiles = getCurrentUserProfiles(state);

    return { user, profiles };
  },
  { logout },
  null,
)(MainMenu);
