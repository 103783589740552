/* eslint-disable jsx-a11y/anchor-is-valid */
import { t } from 'i18next';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form/immutable';
import withRouter from 'routes/withRouter';

import { getVersionUri } from 'utils';
import { existingTeamSignUp } from 'utils/urls';

import { fetchOauthConfiguration, fromOauthConfiguration } from 'data/features/oauthConfiguration';
import Errors from '../components/Errors';
import { getEnvVar } from '../config';
import { checkOtpFlow, linkedInLogin, load, login } from '../data/actions/users';
import { getLoginLoading } from '../data/reducers';
import Briefcase from './assets/briefcase.png';
import LoginForm from './components/LoginForm';
import ConfigureMFA from './components/MFASetup/ConfigureMFA';
import OauthButtonForm, { findIdp } from './components/OauthButtonForm';
import OauthButtons from './components/OauthButtons';
import OauthError from './components/OauthError';
import './Login.scss';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOtpField: false,
      showPasswordField: false,
      showMFAModal: false,
      otpToken: null,
      userName: null,
      selectedIdp: null,
    };
  }

  componentDidMount() {
    this.props.fetchOauthConfiguration();
  }

  throwOnError = (response, username) => {
    if (response.error) {
      if (response.payload.status === 403) {
        throw new SubmissionError({
          _error: t('auth.login.error.account_locked', {
            email: getEnvVar('REACT_APP_SUPPORT_EMAIL', 'support@valideval.com'),
            username,
          }),
        });
      }

      if (response.payload.status === 401) {
        throw new SubmissionError({
          _error: t('auth.login.error.sso_error', {
            email: getEnvVar('REACT_APP_SUPPORT_EMAIL', 'support@valideval.com'),
          }),
        });
      }
      const message = this.state.showOtpField
        ? 'The user / password / MFA code combination is invalid.'
        : 'The user / password combination is invalid.';
      const errorMessage = response.payload.status === 404 ? message : response.payload.statusText;
      throw new SubmissionError({ _error: errorMessage });
    }
    return Promise.resolve(response);
  };

  alreadyRegisteredEmailText = () => {
    const { alreadyRegisteredEmail } = this.props;

    if (this.props.firstLogin) {
      return (
        <p>
          Welcome back! We've recently done a major technology upgrade. As a result we need you to
          create a new password then login.
          <br />
          <br />
          Click{' '}
          <Link to="/forgot-password" target="_blank">
            <b>here</b>
          </Link>{' '}
          to reset your password in a new browser tab.
        </p>
      );
    }

    return `Since you already have an account associated with ${alreadyRegisteredEmail}, please login. Then accept your invitation via the "Notifications" link in the top right corner of your screen.`;
  };

  handleLinkedInLogin = (e) => {
    e.preventDefault();
    const { alreadyExistingTeamContact, slug, email, first_name, last_name } = this.props.query;
    const queryParams = alreadyExistingTeamContact ? { slug, email, first_name, last_name } : null;

    this.props.linkedInLogin(queryParams);
  };

  handleToggleModal = () => {
    this.setState({ showMFAModal: !this.state.showMFAModal });
  };

  fetchLoggedInUser = () => this.props.load('me');

  navigateToOtpConfig = (otpRequestToken) => {
    const { navigate } = this.props;

    navigate(`otp_config/${otpRequestToken}`);
  };

  navigateToApp = () => {
    const {
      query: { alreadyExistingTeamContact, slug, email, first_name, last_name, redirect },
    } = this.props;

    let redirectUrl = alreadyExistingTeamContact
      ? existingTeamSignUp(slug, email, first_name, last_name)
      : undefined;

    redirectUrl = redirectUrl || redirect;
    redirectUrl && (window.location.href = redirectUrl);
  };

  onSubmit = (values) => {
    const { username, password, otp } = values.toJS();
    const { oAuthNeedLogin, onLoginSuccess } = this.props;

    if (this.props.oauthEnabled) {
      const idp = findIdp(this.props.oauthIdps, username);
      if (idp) return this.setState({ selectedIdp: idp });
    }

    if (!this.state.showPasswordField) return this.setState({ showPasswordField: true });

    return this.props.checkOtpFlow(username, password).then((response) => {
      const { otpRequired, otpConfigured, otpRequestToken } = response.payload;

      if (otpRequired && !otpConfigured) {
        return this.throwOnError(response).then(() => {
          this.setState({ showMFAModal: true, otpToken: otpRequestToken, userName: username });
        });
      }

      if (otpRequired && !this.state.showOtpField) {
        return this.throwOnError(response).then(this.showOtpField);
      } else {
        return this.props
          .login(username, password, otp)
          .then((response) => this.throwOnError(response, username))
          .then(this.fetchLoggedInUser)
          .then(oAuthNeedLogin ? onLoginSuccess : this.navigateToApp);
      }
    });
  };

  showOtpField = () => {
    this.setState({ showOtpField: true });
  };

  render() {
    const { alreadyRegisteredEmail, oAuthNeedLogin } = this.props;

    return (
      <div className="container">
        <div className="login-container mb-5">
          <h1 className="login-title mt-3 mb-3">Log In {oAuthNeedLogin ? 'to continue' : ''}</h1>
          <div className="login-box d-flex flex-column">
            <h2 className="login-subtitle mb-4">{getVersionUri()}</h2>
            <OauthError />
            {alreadyRegisteredEmail && (
              <Errors
                errors={[this.alreadyRegisteredEmailText()]}
                alertClasses={{ alert: 'alert alert-danger alert-inline' }}
              />
            )}
            {oAuthNeedLogin && (
              <div className="alert alert-info">
                Thank you for confirming your identity. Please log in to your Valid Eval profile to
                link your Single Sign-On credentials.
                <br />
                <br />
                If you don't remember your password or need to reset it, please click{' '}
                <Link to="/forgot-password">here</Link>.
              </div>
            )}
            {(getEnvVar('REACT_APP_GOV_ENV') === '0' || this.props.oauthEnabled) &&
              !oAuthNeedLogin && (
                <div className="d-grid gap-2">
                  <OauthButtons onLinkedInLogin={this.handleLinkedInLogin} />
                </div>
              )}
            <div style={{ flex: 1 }}>
              <LoginForm
                onSubmit={this.onSubmit}
                showOtpField={this.state.showOtpField}
                showPasswordField={this.state.showPasswordField}
              />
              {this.state.selectedIdp && (
                <OauthButtonForm idp={this.state.selectedIdp} autoSubmit />
              )}
            </div>
            <div className="text-center mt-4 mb-2">
              <img src={Briefcase} className="login-footer-image" alt="Briefcase little icon" />
            </div>
          </div>
        </div>
        {this.state.otpToken && (
          <ConfigureMFA
            show={this.state.showMFAModal}
            onToggle={this.handleToggleModal}
            otpRequestToken={this.state.otpToken}
            extras={{ userName: this.state.userName }}
          />
        )}
      </div>
    );
  }
}

Login.displayName = 'Login';
export default withRouter(
  connect(
    (state, ownProps) => {
      const location = ownProps.location;
      const query = ownProps.query;
      return {
        loading: getLoginLoading(state),
        alreadyRegisteredEmail: query.alreadyRegisteredEmail,
        firstLogin: query.firstLogin,
        testLogin: query.testLogin,
        location,
        oauthEnabled: fromOauthConfiguration.enabled(state?.toJS()),
        oauthIdps: fromOauthConfiguration.idps(state?.toJS()),
      };
    },
    {
      login,
      checkOtpFlow,
      linkedInLogin,
      load,
      fetchOauthConfiguration,
    },
  )(Login),
);
