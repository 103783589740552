import { Button } from '@valid-eval/shared-react-components';
import { getVersionUri } from 'utils';

const OauthConfirmCreateUser = () => {
  return (
    <div className="container">
      <div className="login-container mb-5">
        <h1 className="login-title mt-3 mb-3">&nbsp;</h1>
        <div className="login-box d-flex flex-column">
          <h2 className="login-subtitle mb-4">{getVersionUri()}</h2>
          <div style={{ flex: 1 }}>
            <h1 className="text-center text-light mt-3 mb-3">
              Thank you for confirming your identity. <br />
              <br /> Do you already have a Valid Eval account?
            </h1>
            <div className="mt-5 d-grid">
              <form action="/api/v1/auth/link" method="post" className="d-grid">
                <Button type="submit" variant="success">
                  Create new VE account
                </Button>
              </form>
              <br />
              <br />
              <a className="btn btn-default" href="/oauth-need-login">
                Log in to existing VE account
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OauthConfirmCreateUser;
