import { teamNav } from '../urls';

export const displayName = 'Feedback available';

export const getActionButtons = (notification) => [
  {
    title: 'View Feedback',
    url: teamNav.results(notification.data.event_id, notification.data.team_id),
    toggleSeen: true,
  },
];

export const getText = (notification) =>
  `Feedback available for the team ${notification.data.team_name} for phase ${notification.data.phase_name} in ${notification.data.cohort_name} cohort`;
