import { fromJS, Map } from 'immutable';
import sortBy from 'lodash/sortBy';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { loadNotifications, NOTIFICATIONS_CHANNEL_RECEIVED } from 'data/features/notifications';

import { actions } from '../actions/phases';
import { actions as teamActions } from '../actions/teams';

const PHASES_CHANNEL_RECEIVED = 'socket/PhasesChannel/received';

const {
  LOAD_PHASES,
  LOAD_PHASES_RESULT,
  LOAD_PHASES_FOR_SELF_EVALUATION,
  LOAD_PHASES_FOR_SELF_EVALUATION_RESULT,
} = teamActions;

const initialState = fromJS({
  data: {},
  loading: false,
  loadingStatus: false,
  status: {},
  byTeam: {},
  byTeamSelfEvaluation: {},
  statusFramework: {},
});

const phases = handleActions(
  {
    [combineActions(
      actions.LOAD,
      actions.LOAD_FOR_EVENT,
      LOAD_PHASES,
      LOAD_PHASES_FOR_SELF_EVALUATION,
    )]: (state, action) => state.set('loading', true),
    [actions.LOAD_RESULT]: {
      next(state, action) {
        return state
          .set('loading', false)
          .mergeDeepIn(['data'], fromJS(action.payload.entities.phases));
      },
      throw(state, action) {
        return state.set('loading', false);
      },
    },
    [actions.UPDATE]: (state, action) => state.set('loading', true),
    [actions.UPDATE_SUCCESS]: (state, action) =>
      state
        .set('loading', false)
        .mergeIn(['data', action.payload.phase.id], fromJS(action.payload.phase)),
    [actions.LOAD_STATUS]: (state, action) => state.set('loadingStatus', true),
    [actions.SAVE_STATUS]: (state, action) =>
      state
        .set('loadingStatus', false)
        .mergeIn(['status', action.payload.phaseId], fromJS(action.payload.status)),
    [actions.LOAD_STATUS_FAIL]: (state, action) => state.set('loadingStatus', false),
    [LOAD_PHASES_RESULT]: {
      next(state, action) {
        const { teamId, entities, result } = action.payload;
        return state
          .set('loading', false)
          .setIn(['byTeam', teamId], result)
          .mergeIn(['data'], fromJS(entities.phases));
      },
      throw(state, action) {
        const { teamId } = action.payload;
        return state.set('loading', false).setIn(['byTeam', teamId], []);
      },
    },
    [LOAD_PHASES_FOR_SELF_EVALUATION_RESULT]: {
      next(state, action) {
        const { teamId, entities, result } = action.payload;
        return state
          .set('loading', false)
          .setIn(['byTeamSelfEvaluation', teamId], result)
          .mergeIn(['data'], fromJS(entities.phases));
      },
      throw(state, action) {
        const { teamId } = action.payload;
        return state.set('loading', false).setIn(['byTeamSelfEvaluation', teamId], []);
      },
    },
    [loadNotifications.fulfilled]: (state, action) => {
      const { notifications } = action.payload;
      notifications.forEach((notification) => {
        if (
          (notification.action === 'automatch' || notification.action === 'panel_match') &&
          notification.data.is_running
        ) {
          state = state.setIn(
            ['data', notification.data.phase_id, 'automatchRunning'],
            notification.data.is_running,
          );
        }
      });
      return state;
    },
    [NOTIFICATIONS_CHANNEL_RECEIVED]: (state, action) => {
      const { notification } = action?.payload || {};
      if (notification?.action === 'automatch' || notification?.action === 'panel_match') {
        return state.setIn(
          ['data', notification?.data?.phase_id, 'automatchRunning'],
          notification?.data?.is_running,
        );
      }
      return state;
    },
    [PHASES_CHANNEL_RECEIVED]: (state, action) => {
      if (action?.payload?.type === 'has_stale_scores' && action?.payload?.phase_id) {
        return state.setIn(['data', action?.payload?.phase_id, 'has_stale_scores'], true);
      }
      if (action?.payload?.type === 'build_statistics_done' && action?.payload?.phase_id) {
        return state.setIn(['data', action?.payload?.phase_id, 'has_stale_scores'], false);
      }
      return state;
    },
  },
  initialState,
);

export const getPhasesByEventId = (state, eventId) =>
  sortBy(
    state
      .get('data')
      .filter((phase) => phase.get('event_id') === eventId)
      .map((phase) => phase.toObject())
      .toArray(),
    ['end_date', 'start_date', 'phase_name'],
  );

export const isLoading = (state) => state.get('loading');

export const isLoadingTeamsStatus = (state, phaseId) =>
  state.get('loadingStatus') || !state.getIn(['status', phaseId, 'teams']);

export const isLoadingJudgesStatus = (state, phaseId) =>
  state.get('loadingStatus') || !state.getIn(['status', phaseId, 'judges']);

export const isLoadingResultsStatus = (state, phaseId) =>
  state.get('loadingStatus') || !state.getIn(['status', phaseId, 'results']);

export const isRunningAutomatch = (state, phaseId) =>
  state.getIn(['data', phaseId, 'automatchRunning']);

export const getTeamsStatus = (state, phaseId) => state.getIn(['status', phaseId, 'teams']);

export const getJudgesStatus = (state, phaseId) => state.getIn(['status', phaseId, 'judges']);

export const getResultsStatus = (state, phaseId) => state.getIn(['status', phaseId, 'results']);

export const getById = (state, id) => state.getIn(['data', id]);

export const getLookup = (state) => state.get('data');
const mapIdsToJsonPhase = (ids, lookup) => {
  return ids.map((phaseId) => lookup.get(phaseId, Map()).toJS());
};

export const getPhasesForTeam = createSelector(
  (state, teamId) => state.getIn(['byTeam', teamId], []),
  getLookup,
  mapIdsToJsonPhase,
);

export const getPhasesForTeamSelfEvaluation = createSelector(
  (state, teamId) => state.getIn(['byTeamSelfEvaluation', teamId], []),
  getLookup,
  mapIdsToJsonPhase,
);

export default phases;
