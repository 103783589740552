import {
  Col,
  Form,
  InputGroup,
  OptionValue,
  Row,
  SelectMultiple,
} from '@valid-eval/shared-react-components';
import cx from 'classnames';
import moment, { Moment } from 'moment-timezone';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Date from 'components/Form/Date';
import { dateIsAfter } from 'components/FormFields/validations';
import { humanize } from 'utils';

export function entitiesToOptions(entities: any[], value = 'id', label = 'name'): OptionValue[] {
  return entities.map((entity) => ({
    label: entity[label],
    value: entity[value],
  }));
}

export type FormValues = {
  artifacts: OptionValue[];
  deadline: Moment | string | null;
  fields: OptionValue[];
};

type ApplicationRevisionRequestFormProps = {
  onSubmit(data: FormValues): Promise<void>;
  artifactOptions: OptionValue[];
  fieldOptions: OptionValue[];
};

function ApplicationRevisionRequestForm({
  onSubmit,
  artifactOptions,
  fieldOptions,
}: ApplicationRevisionRequestFormProps) {
  const { t } = useTranslation();
  const { control, handleSubmit, formState, getValues, clearErrors } = useFormContext<FormValues>();
  const currentTimezone = moment.tz.guess();

  const noEmptyRevisionFields = () => {
    const { fields, artifacts } = getValues();
    return fields.length > 0 || artifacts.length > 0
      ? undefined
      : t('application_revision_request.error.empty_fields');
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="p-3">
        <Col md={12} className="mb-4">
          <Form.Label>
            <b>{t('application_revision_request.fields.deadline')}</b>
            <small className="text-muted">
              &nbsp;{t('application_revision_request.fields.deadline_info')}
            </small>
          </Form.Label>
          <Date
            id="application-revision-request-deadline"
            placeholder={t('application_revision_request.fields.deadline_placeholder')}
            name="deadline"
            rules={{
              validate: { dateIsAfter: dateIsAfter(moment()) },
            }}
            after={
              <InputGroup.Text>
                GMT{moment.tz(currentTimezone).format('Z')} - {humanize(currentTimezone)}
              </InputGroup.Text>
            }
            isDateTime
          />
        </Col>
        <Col md={6}>
          <Form.Label>
            <b>{t('application_revision_request.fields.fields')}</b>
          </Form.Label>
          <Controller
            control={control}
            name="fields"
            rules={{ validate: { noEmptyRevisionFields } }}
            render={({ field: { onChange, value } }) => (
              <SelectMultiple
                id="application-revision-request-fields"
                className={cx('form-control', { 'is-invalid': formState.errors?.fields })}
                options={fieldOptions}
                value={value}
                onChange={(...args) => {
                  clearErrors(['artifacts']);
                  return onChange(...args);
                }}
              />
            )}
          />
          {formState.errors?.fields && (
            <div className="invalid-feedback d-block">{formState.errors.fields.message}</div>
          )}
        </Col>

        <Col md={6}>
          <Form.Label>
            <b>{t('application_revision_request.fields.artifacts')}</b>
          </Form.Label>
          <Controller
            control={control}
            name="artifacts"
            rules={{ validate: { noEmptyRevisionFields } }}
            render={({ field: { onChange, value } }) => (
              <SelectMultiple
                id="application-revision-request-artifacts"
                className={cx('form-control', { 'is-invalid': formState.errors?.artifacts })}
                options={artifactOptions}
                value={value}
                onChange={(...args) => {
                  clearErrors(['fields']);
                  return onChange(...args);
                }}
              />
            )}
          />
          {formState.errors?.artifacts && (
            <div className="invalid-feedback d-block">{formState.errors.artifacts.message}</div>
          )}
        </Col>
      </Row>
      <button type="submit" style={{ display: 'none' }}></button>
    </Form>
  );
}

export default ApplicationRevisionRequestForm;
