import { Middleware } from 'redux';

import { getEnvVar } from 'config';

import { add as notifyError } from '../../data/actions/notifications';
import { logoutSuccess } from '../../data/actions/users';

type BasicAction = {
  type: string;
  payload?: any;
};

const isApiError = (action: BasicAction) => action.payload && action.payload.name === 'ApiError';

export const handleApiErrors: Middleware = (store) => (next) => (action: any) => {
  if (!isApiError(action)) return next(action);

  if (action.payload.status === 401) {
    store.dispatch(logoutSuccess());
    if (action.type !== 'users/LOAD_RESULT' && action.type !== 'users/LOGIN_RESULT')
      store.dispatch(
        notifyError(
          'danger',
          'Your session has expired, please login again',
          null,
          parseInt(getEnvVar('REACT_APP_ALERT_DURATION', '3000')),
          new Date(1, 1, 1979).getTime(),
        ),
      );
  }

  return next(action); // Allow the failed action to move on, so reducers can change state accordingly
};
