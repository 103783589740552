import { Map } from 'immutable';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Loading from 'components/Loading';
import { load, loginResult } from 'data/actions/users';
import { getCurrentUser } from 'data/reducers';
import OauthError from './components/OauthError';

const connector = connect(
  (state: Map<string, any>) => ({
    user: getCurrentUser(state)?.toJS(),
  }),
  { load, loginResult },
);

const OauthComplete = ({ user, load, loginResult }: ConnectedProps<typeof connector>) => {
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const query = qs.parse(location.search);

  useEffect(() => {
    if (!query.error) {
      (async () => {
        // @ts-ignore
        const { payload } = await load('me');
        loginResult(payload.result);
        setTimeout(() => navigate(String(query.url || '/')), 300);
      })();
    } else {
      setError(true);
    }
  }, []);

  useEffect(() => {
    if (!user && error) navigate(`/${location.search}`);
  }, [user, error]);

  return (
    <>
      <br />
      {!error && <Loading text="" />}
      {error && <OauthError />}
    </>
  );
};

export default connector(OauthComplete);
