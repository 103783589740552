import { Map } from 'immutable';
import { connect, ConnectedProps } from 'react-redux';

import { getEnvVar } from 'config';
import { fromOauthConfiguration } from 'data/features/oauthConfiguration';

import LinkedInButton from './LinkedInButton';
import OauthButtonForm from './OauthButtonForm';

type OwnProps = {
  onLinkedInLogin?: () => void;
  signUp?: boolean;
};

const mapStateToProps = (state: Map<string, any>) => ({
  oauthEnabled: fromOauthConfiguration.enabled(state?.toJS()),
  oauthIdps: fromOauthConfiguration.idps(state?.toJS()),
});

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & OwnProps;

const OauthButtons = ({ onLinkedInLogin, signUp, oauthEnabled, oauthIdps }: Props) => {
  const govEnv = getEnvVar('REACT_APP_GOV_ENV');
  const show = govEnv === '0' || oauthEnabled;

  return show ? (
    <>
      {govEnv === '0' && !oauthEnabled && onLinkedInLogin && (
        <LinkedInButton text="Linkedin Login" login={onLinkedInLogin} />
      )}
      {oauthEnabled && (
        <>
          {oauthIdps.map((idp) => (
            <OauthButtonForm key={idp.name} idp={idp} signUp={signUp} />
          ))}
        </>
      )}
      <h3 className="text-center text-white01 mt-2 p-0 mb-0">or</h3>
    </>
  ) : null;
};

export default connector(OauthButtons);
