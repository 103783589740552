import './NotificationsList.module.scss';

import { Button, OverlayTrigger, Tooltip } from '@valid-eval/shared-react-components';
import { notificationActionButtons, notificationTextFromData } from '../../../utils/notifications';

import LinkContainer from 'components/LinkContainer';
import { useNavigate } from 'react-router-dom';

const tooltip = (seen) => <Tooltip>Mark as {seen ? 'unread' : 'read'}</Tooltip>;

export default function NotificationItem({ notification, onToggleSeen, onCloseList }) {
  const actionButtons = notificationActionButtons(notification);
  const navigate = useNavigate();

  const handleClick = (notification, url, toggle, blank) => (event) => {
    event.preventDefault();
    !notification.seen && toggle && onToggleSeen(notification);
    setTimeout(() => {
      blank ? window.open(url, '_blank') : navigate(url);
      onCloseList();
    }, 0);
  };

  return (
    <li className={`${notification.seen ? '' : 'unseen'}`} id={`notification-${notification.id}`}>
      <div className="flex-grow-1">
        <div className="stack-list-left">
          <p
            className="stack-list-text"
            dangerouslySetInnerHTML={{ __html: notificationTextFromData(notification) }}
          />
        </div>
        {actionButtons?.map((ActionButton, index) =>
          ActionButton.url ? (
            <LinkContainer
              to={ActionButton.url}
              className="float-end"
              key={`notification-${notification.id}-action-button-${index}`}
              onClick={handleClick(
                notification,
                ActionButton.url,
                ActionButton.toggleSeen,
                ActionButton.blank,
              )}
            >
              <Button
                variant={ActionButton.type ?? 'link'}
                className="notification-action-button btn-small"
                dangerouslySetInnerHTML={{ __html: ActionButton.title }}
              />
            </LinkContainer>
          ) : (
            <ActionButton key={`notification-${notification.id}-action-button-${index}`} />
          ),
        )}
      </div>
      <OverlayTrigger placement="top" overlay={tooltip(notification.seen)}>
        <div className="text-xs">
          <a
            role="button"
            href="#toggle-read"
            onClick={(e) => {
              e.preventDefault();
              onToggleSeen(notification);
            }}
          >
            <i
              className={notification.seen ? 'fa-regular fa-circle' : 'fa-solid fa-circle'}
              aria-hidden="true"
            ></i>
          </a>
        </div>
      </OverlayTrigger>
    </li>
  );
}
