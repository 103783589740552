import { Button } from '@valid-eval/shared-react-components';
import { Map } from 'immutable';
import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router';

import Loading from 'components/Loading';
import { fetchNotes, fromNotes } from 'data/features/notes';

import AddNote from './AddNote';
import { getUserRole, SortBy, sortNotes } from './helpers';
import NotesList from './NotesList';

type OverallNotesProps = {
  phaseId: string;
  teamId: string;
  isOrganizerScreen: boolean;
  isTeamScreen: boolean;
  judgeIdFilter?: string;
  redacted?: boolean;
  judgeRedactedNames?: Record<string, string>;
  excludeDimensionNotes?: boolean;
};

const mapDispatchToProps = {
  fetchNotes,
};

const mapStateToProps = (state: Map<string, any>, ownProps: OverallNotesProps) => ({
  loading: fromNotes.getIsLoading(state?.toJS()),
  notes: fromNotes.getOverallNotes(state?.toJS(), {
    phaseId: ownProps.phaseId,
    teamId: ownProps.teamId,
    judgeIdFilter: ownProps.judgeIdFilter,
    redacted: ownProps.redacted,
    judgeRedactedNames: ownProps.judgeRedactedNames,
    excludeDimensionNotes: ownProps.excludeDimensionNotes,
  }),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const OverallNotes = ({
  fetchNotes,
  isOrganizerScreen,
  isTeamScreen,
  loading,
  notes,
  phaseId,
  teamId,
}: OverallNotesProps & ConnectedProps<typeof connector>) => {
  const [sortBy, setSortBy] = useState<SortBy>('dimension');
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const unredacted = urlParams.get('unredacted') === '1';

  const fetch = () => {
    fetchNotes({ phaseId, teamId, role: getUserRole(isOrganizerScreen, isTeamScreen) });
  };

  useEffect(() => {
    fetch();
  }, [teamId, phaseId]);

  const sortedNotes = sortNotes(notes, sortBy);

  return (
    <>
      <h2 className={'d-print-none'}>{isTeamScreen ? 'Comments' : 'Overall Comments'}</h2>
      <div id="add-overall-note" className="mb-3">
        <AddNote isOrganizerScreen={isOrganizerScreen} phaseId={phaseId} teamId={teamId} />
      </div>
      {loading && <Loading />}
      {!loading && (
        <>
          {isOrganizerScreen && (
            <div className="d-flex justify-content-between align-items-center mb-3 d-print-none">
              <strong>Sort comments by:</strong>
              <div className="flex-grow-1 ms-2">
                <Button
                  className="me-2"
                  size="sm"
                  variant={sortBy === 'dimension' ? 'success' : 'default'}
                  onClick={() => setSortBy('dimension')}
                >
                  {sortBy === 'dimension' && <i className="fa-solid fa-check" />}
                  Rubric Order
                </Button>
                <Button
                  size="sm"
                  variant={sortBy === 'netVotes' ? 'success' : 'default'}
                  onClick={() => setSortBy('netVotes')}
                >
                  {sortBy === 'netVotes' && <i className="fa-solid fa-check" />}
                  Net votes
                </Button>
              </div>
            </div>
          )}
          <div id="overall-notes-list">
            <NotesList
              notes={sortedNotes}
              isOrganizerScreen={isOrganizerScreen}
              phaseId={phaseId}
              teamId={teamId}
              showDimensionName
              unredacted={unredacted}
            />
          </div>
        </>
      )}
    </>
  );
};

export default connector(OverallNotes);
