import { organizerNav } from '../urls';

export const getActionButtons = (notification) => [
  {
    title: 'Remind your judges',
    url: `${organizerNav.judgeManage(
      notification.data.event_id,
    )}?phase=${notification.data.phase_id}`,
  },
];

export const getText = (notification) => {
  return `In ${notification.data.time_left} the judging window will close for cohort ${notification.data.cohort_name}, phase ${notification.data.phase_name}. Take action to remind your Judges.`;
};
