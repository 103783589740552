import { Button, Form } from '@valid-eval/shared-react-components';
import parse from 'html-react-parser';
import { Map } from 'immutable';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';

import Errors from 'components/Errors';
import Email from 'components/Form/Email';
import Input from 'components/Form/Input';
import Password from 'components/Form/Password';
import { name, passwordStrength, sanitizeName } from 'components/FormFields/validations';
import { getEnvVar } from 'config';
import { fromOauthConfiguration } from 'data/features/oauthConfiguration';
import withRouter from 'routes/withRouter';

import OauthButtons from '../OauthButtons';
import { SignUpFormType } from './types';

type OwnProps = {
  isLoading: boolean;
  error: string | null;
  onNext(data: SignUpFormType): void;
  onRequestVerificationCode(): void;
  wasCodeSend: boolean;
  invitationId: string | null;
  token: string | null;
  registered: string | undefined;
  isTeamMemberInvitation: boolean;
};

const mapStateToProps = (state: Map<string, any>) => ({
  oauthEnabled: fromOauthConfiguration.enabled(state?.toJS()),
});

const connector = connect(mapStateToProps);

type SignUpFormProps = ConnectedProps<typeof connector> & OwnProps;

const SignUpForm = ({
  error,
  isLoading,
  wasCodeSend,
  invitationId,
  token,
  onNext,
  onRequestVerificationCode,
  registered,
  isTeamMemberInvitation,
  oauthEnabled,
}: SignUpFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, getValues, setFocus } = useFormContext<SignUpFormType>();

  let message = wasCodeSend && !token ? t('auth.sign_up.code_send') : '';
  message = invitationId && !isTeamMemberInvitation ? t('auth.sign_up.invitation') : message;

  useEffect(() => {
    setFocus(wasCodeSend || !!invitationId || isTeamMemberInvitation ? 'first_name' : 'email');
  }, []);

  return (
    <>
      {(getEnvVar('REACT_APP_GOV_ENV') === '0' || oauthEnabled) && (
        <div className="d-grid gap-2 mb-4">
          <OauthButtons signUp />
        </div>
      )}
      <Form onSubmit={handleSubmit(onNext)} noValidate>
        <Errors
          errors={error ? [error] : null}
          alertClasses={{ alert: 'alert alert-danger alert-inline' }}
        />
        <Email
          id="signup-email"
          name="email"
          disabled={!!token || !!invitationId}
          label={t('auth.sign_up.username')}
          required
          autoComplete={'off'}
        />
        {(wasCodeSend || !!invitationId || isTeamMemberInvitation) && (
          <>
            {!token && !isTeamMemberInvitation && (
              <div className="alert alert-info mb-2">{parse(message)}</div>
            )}
            {!invitationId && !isTeamMemberInvitation && (
              <Input
                id="signup-code"
                name="code"
                label={t('auth.sign_up.code')}
                required
                disabled={!!token}
                after={
                  <Button variant="primary" disabled={!!token} onClick={onRequestVerificationCode}>
                    {t('auth.sign_up.resend_code')}
                  </Button>
                }
              />
            )}

            <Input
              id="signup-first_name"
              name="first_name"
              label={t('auth.sign_up.first_name')}
              format={sanitizeName}
              rules={{ validate: { name } }}
              required
            />
            <Input
              id="signup-last_name"
              name="last_name"
              label={t('auth.sign_up.last_name')}
              format={sanitizeName}
              rules={{ validate: { name } }}
              required
            />
            <Password
              id="signup-password"
              name="password"
              label={t('auth.sign_up.password')}
              rules={{ validate: { passwordStrength } }}
              required
            />
            <Password
              id="signup-password_confirmation"
              name="password_confirmation"
              label={t('auth.sign_up.password_confirmation')}
              rules={{
                validate: {
                  passwordStrength,
                  match(value: string) {
                    const { password } = getValues();
                    return password === value || t('auth.sign_up.password_confirmation_not_match');
                  },
                },
              }}
              required
            />
          </>
        )}
        <div className="d-grid gap-2 mt-4">
          <Button id="signup-submit" type="submit" variant="success" disabled={isLoading}>
            {isLoading && <i className="fa-duotone fa-spinner fa-spin" />}
            {!isLoading &&
              t(
                wasCodeSend || invitationId || isTeamMemberInvitation
                  ? 'auth.sign_up.title'
                  : 'common.continue',
              )}
          </Button>
        </div>
        {!registered && !isTeamMemberInvitation && (
          <div className="mt-4">
            {t('auth.sign_up.existing_account')}{' '}
            <Link to={'#login'} className="text-blue01">
              {t('auth.login.title')}
            </Link>
          </div>
        )}
      </Form>
    </>
  );
};

export default withRouter(connector(SignUpForm));
