import cx from 'classnames';

import { relativeTime } from 'utils';
import { Note as NoteType } from 'data/features/notesTypes';

import Styles from './Note.module.scss';

type NoteHeaderProps = {
  note: NoteType;
  isFirstInternal: boolean;
  showDimensionName?: boolean;
  isOrganizerScreen?: boolean;
  unredacted?: boolean;
};

const NoteHeader = ({
  note,
  isFirstInternal,
  showDimensionName,
  isOrganizerScreen,
  unredacted,
}: NoteHeaderProps) => {
  let dimensionName = 'Overall';
  if (note.dimensionName) {
    dimensionName = note.dimensionName;
  }

  return (
    <>
      {isFirstInternal && (
        <div className={cx('mb-2', Styles.NoteInternalIndicator)}>
          <strong className="text-orange02 text-xs text-uppercase">
            Only visible to selection committee
          </strong>
        </div>
      )}
      <div className="d-flex justify-content-between align-items-center mb-2">
        <small className="text-muted">
          {showDimensionName && <>{dimensionName} / </>}
          {note.writer}
          {note.isYou && <small className="ms-1 d-print-none">(this is you)</small>}:
        </small>
        <small className={cx('text-muted text-xs', { 'd-print-none': !unredacted })}>
          {relativeTime(note.createdAt)}
          {isOrganizerScreen && note.edited && ' (edited)'}
        </small>
      </div>
    </>
  );
};

export default NoteHeader;
