import { configureStore as configureStoreRTK } from '@reduxjs/toolkit';
import { apiMiddleware } from 'redux-api-middleware';
// @ts-expect-error redux-persist-immutable is not correct typed
import { persistStore } from 'redux-persist-immutable';

import createSagaMiddleware from 'redux-saga';
import rootReducer from '../data/reducers';
import rootSaga from '../data/sagas';
import { handleApiErrors } from './middlewares/api';
import { handleSocket } from './middlewares/socket';

// Declare store property on Window interface to fix TypeScript error
declare global {
  interface Window {
    store: any;
  }
}

const configureStore = ({ preloadState }: { preloadState: any }) => {
  const saga = createSagaMiddleware();

  const store = configureStoreRTK({
    reducer: rootReducer(),
    // @ts-expect-error apiMiddleware is not correct typed
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      })
        .concat(apiMiddleware)
        .concat(handleSocket)
        .concat(saga)
        .concat(handleApiErrors),
    preloadedState: preloadState,
  });

  persistStore(store, {
    whitelist: ['users', 'selectedPhases'],
  });
  saga.run(rootSaga);

  window.store = store;
  return store;
};

export default configureStore;
