import { Alert } from '@valid-eval/shared-react-components';
import parse from 'html-react-parser';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getEnvVar } from 'config';

const OauthError = () => {
  const email = getEnvVar('REACT_APP_SUPPORT_EMAIL', 'support@valideval.com');

  const { t } = useTranslation();
  const location = useLocation();
  const query = qs.parse(location.search);

  return query.error ? (
    <Alert variant="danger">
      {parse(
        t(
          [
            `auth.${query.error}`,
            t(
              'auth.generic_error',
              'There was an error linking your Single Sign-On account information. Please reach out to {{email}} for further assistance.',
              { email },
            ),
          ],
          { email },
        ),
      )}
    </Alert>
  ) : null;
};

export default OauthError;
