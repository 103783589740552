import { Button } from '@valid-eval/shared-react-components';
import noop from 'lodash/noop';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ApplicationRevisionRequest } from 'data/features/applicationRevisionRequestsTypes';
import { useCommunicationTemplates } from 'data/reducers/communications';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';
import { teamsCommunications } from 'utils/notifications';
import ApplicationRevisionRequestModal from './ApplicationRevisionRequestModal';

import CommunicationsNotify from '../communications/CommunicationsNotify';

type ApplicationRevisionRequestButtonProps = {
  applicationRevisionRequest: ApplicationRevisionRequest;
  artifacts: any[];
  fields: any[];
  loading: boolean;
  phaseId: string;
};

function ApplicationRevisionRequestButton({
  applicationRevisionRequest,
  artifacts,
  fields,
  loading,
  phaseId,
}: ApplicationRevisionRequestButtonProps) {
  const { t } = useTranslation();
  const params = useParams<{ team_id: string; event_id: string }>();
  const [showModal, open, close] = useBooleanFlag(false);
  const [showCommsModal, openCommsModal, closeCommsModal] = useBooleanFlag(false);
  const [currentTemplate, setCurrentTemplate] = useState<any>(null);
  const submitApplicationRevisionRequest = useRef(noop);

  const templates = useCommunicationTemplates({
    templates: ['team_revision_application'],
    eventId: params.event_id,
    phaseId,
  });

  useEffect(() => {
    if (!templates.length) return;

    const defaultTemplate = templates[0];
    if (!currentTemplate || defaultTemplate?.id !== currentTemplate?.id) {
      setCurrentTemplate(templates[0]);
    }
  }, [currentTemplate?.id, templates.map((t) => t.id).join(',')]);

  const handleNext = (submit: () => Promise<void>) => {
    submitApplicationRevisionRequest.current = submit;
    openCommsModal();
  };

  const beforeSend = async () => {
    if (submitApplicationRevisionRequest.current) {
      await submitApplicationRevisionRequest.current();
      submitApplicationRevisionRequest.current = noop;
    }
  };

  const label = applicationRevisionRequest
    ? t('application_revision_request.button_requested', 'Application revision requested')
    : t('application_revision_request.button_request', 'Request application revision');

  return (
    <>
      <CommunicationsNotify
        afterSend={noop}
        beforeSend={beforeSend}
        disableTemplateSelection
        disableConfirmation
        closeModalForm={(canceled = false) => {
          if (canceled) open();
          closeCommsModal();
        }}
        communications={teamsCommunications}
        currentTemplate={currentTemplate}
        extraData={{ teamProfileIds: [params.team_id] }}
        sendTo="teams"
        setCurrentTemplate={setCurrentTemplate}
        showModalForm={showCommsModal}
      />
      <ApplicationRevisionRequestModal
        teamIds={[params.team_id || '']}
        applicationRevisionRequest={applicationRevisionRequest}
        artifacts={artifacts}
        close={close}
        fields={fields}
        show={showModal}
        onNext={handleNext}
      />
      <Button
        id="application-revision-request-button"
        disabled={loading}
        onClick={open}
        variant={applicationRevisionRequest ? 'secondary' : 'success'}
      >
        {label}
      </Button>
    </>
  );
}

export default ApplicationRevisionRequestButton;
