import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { load as loadEvaluation, loadForPhase as loadEvaluations } from 'data/actions/evaluations';
import { load as loadPhaseSummary } from 'data/actions/phase_summaries';
import { load as loadRubric } from 'data/actions/rubrics';
import { loadForEvaluation as loadScores } from 'data/actions/scores';

export const usePhaseData = (phase) => {
  const dispatch = useDispatch();

  const loadPhaseData = () => {
    dispatch(loadEvaluations(phase.id));
    dispatch(loadRubric(phase.id));
    dispatch(loadPhaseSummary(phase.phase_summary_id));
  };

  useEffect(() => {
    if (phase?.id) {
      loadPhaseData();
    }
  }, [phase?.id]);

  return loadPhaseData;
};

export const useTeamData = (team, phase) => {
  const dispatch = useDispatch();

  const loadTeamData = (teamId) => {
    dispatch(loadEvaluation(teamId));
    dispatch(loadScores(teamId));
  };

  useEffect(() => {
    if (team?.id && !team.judge_scores) {
      loadTeamData(team.id);
    }
  }, [team?.id]);

  return loadTeamData;
};
