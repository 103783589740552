import { Alert } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useLayoutEffect, useState } from 'react';

import Styles from './StaleScoresAlert.module.scss';
import { useTranslation } from 'react-i18next';

type StaleScoresAlertProps = {
  show: boolean;
};

const MARGIN = 10;
const FOOTER_HEIGHT = 141;

const StaleScoresAlert = ({ show }: StaleScoresAlertProps) => {
  const { t } = useTranslation();
  const [margin, setMargin] = useState(FOOTER_HEIGHT + MARGIN);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (document.body.scrollHeight <= window.innerHeight) {
        setMargin(FOOTER_HEIGHT + MARGIN);
      } else {
        const distanceToBottom = document.body.scrollHeight - (window.innerHeight + window.scrollY);
        setMargin(Math.max(MARGIN + FOOTER_HEIGHT - distanceToBottom, MARGIN));
      }
    };

    // Create resize observer to watch for body content changes
    const resizeObserver = new ResizeObserver(() => {
      handleScroll();
    });

    // Start observing the body
    resizeObserver.observe(document.body);

    // Handle initial scroll and scroll events
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      resizeObserver.disconnect();
    };
  }, []);

  if (!show) return null;

  return (
    <Alert
      variant="warning"
      className={cx('py-4 px-2 d-print-none m-0', Styles.StaleScoresAlert)}
      style={{ bottom: margin }}
    >
      <div className="d-flex align-items-center">
        <i className="fa-regular fa-shield-check fa-lg me-2 text-orange01"></i>
        <small>{t('phases.stale_scores_pending')}</small>
      </div>
    </Alert>
  );
};

export default StaleScoresAlert;
