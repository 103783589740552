import { Col, OverlayTrigger, Tooltip } from '@valid-eval/shared-react-components';
import cx from 'classnames';

import { Map } from 'immutable';
import Styles from './Heat.module.scss';

type SubdimensionNameProps = {
  subdimension?: Map<string, any>;
  isDimesionNotesOpen: boolean;
};

const SubdimensionName = ({ subdimension }: SubdimensionNameProps) => {
  const appPrompt = subdimension?.get('appPrompt');

  return (
    <Col className={cx(Styles.Subdimension, 'p-2 fw-bold text-black01 d-flex align-items-center')}>
      <span className="flex-grow-1 text-end">{subdimension?.get('name')}</span>
      {appPrompt && (
        <OverlayTrigger
          trigger="click"
          placement="right"
          rootClose
          overlay={
            <Tooltip>
              <div className="p-3 text-start lh-1">{appPrompt}</div>
            </Tooltip>
          }
        >
          <i
            className={'fa-regular fa-circle-question fa-md ms-2 text-gray02 d-print-none'}
            role="button"
          />
        </OverlayTrigger>
      )}
    </Col>
  );
};

export default SubdimensionName;
