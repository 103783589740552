import { teamNav } from '../urls';

export const displayName = 'Take Survey';

export const getActionButtons = (notification) => [
  {
    title: 'Take survey',
    url: teamNav.survey(
      notification.data.event_id,
      notification.data.phase_id,
      notification.data.team_id,
    ),
    toggleSeen: true,
  },
];

export const getText = (notification) =>
  `Valid Eval post Round of Evaluation Survey is now available for the team ${notification.data.team_name}, for phase ${notification.data.phase_name} in cohort ${notification.data.cohort_name}.`;
