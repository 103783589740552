import { Button, Dropdown, Select } from '@valid-eval/shared-react-components';
import cx from 'classnames';

import LinksAndDownloads from 'components/LinksAndDownloads';
import { isNavigator, isTeamLead } from 'utils';

import Styles from './EvaluationSelector.module.scss';

const EvaluationSelector = (props) => {
  const {
    nextEvaluation,
    previousEvaluation,
    value,
    options,
    phaseIndividualScoresEnabled,
    onChange,
  } = props;
  const disablePrevious = options.length === 0 || value === null || value === options[0].value;
  const disableNext = options.length === 0 || value === options[options.length - 1].value;

  return (
    <div
      className={cx(
        Styles.Wrapper,
        'd-inline-flex align-items-center justify-content-between mt-4 mb-3 flex-wrap',
      )}
    >
      <div className="d-flex">
        {phaseIndividualScoresEnabled && (
          <>
            <div className={Styles.SelectWrapper}>
              <Select
                key={value}
                name="individual-evaluation"
                placeholder="Select Evaluation..."
                value={options.find((option) => option.value === value)}
                options={options}
                onChange={onChange}
              />
            </div>

            <Button
              data-test-id="evaluations-navigate-prev"
              className={cx('ms-3 p-0 text-center', Styles.Button)}
              variant={'default'}
              disabled={disablePrevious}
              onClick={previousEvaluation}
            >
              <i className="m-0 fa-solid fa-angle-left fa-lg" />
            </Button>

            <Button
              data-test-id="evaluations-navigate-next"
              className={cx('ms-3 p-0 text-center', Styles.Button)}
              variant={'default'}
              disabled={disableNext}
              onClick={nextEvaluation}
            >
              <i className="m-0 fa-solid fa-angle-right fa-lg" />
            </Button>
          </>
        )}
      </div>
      <div className="d-flex flex-row">
        <LinksAndDownloads
          teamId={props.selectedId}
          eventId={props.eventId}
          documents={props.documents}
          showOrganizerLinks={props.isOrganizer}
        />

        {props.isOrganizer && (
          <Dropdown>
            <Dropdown.Toggle variant="link" id="export-pdf">
              <i className="fa-solid fa-print fa-lg" />
              Export PDF
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={props.onClickPrint({ isUnredacted: true, displayDetails: true })}
                id="export-pdf-selection-committee"
              >
                For Selection Committee
              </Dropdown.Item>
              <Dropdown.Item
                disabled={isNavigator()}
                onClick={props.onClickPrint({ isUnredacted: false, displayDetails: false })}
                id="export-pdf-team"
              >
                For Team
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

        {!props.isOrganizer && !isTeamLead() && (
          <Button
            variant="link"
            className="float-end"
            onClick={props.onClickPrint}
            id="export-pdf-team"
          >
            <i className="fa-solid fa-print fa-lg" />
            Export PDF
          </Button>
        )}
      </div>
    </div>
  );
};

EvaluationSelector.displayName = 'EvaluationSelector';
export default EvaluationSelector;
