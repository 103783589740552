import { Button, Col, Form, Modal, Row } from '@valid-eval/shared-react-components';
import { CurrentRole, send } from 'data/actions/support';
import { Email, Input, Textarea } from 'components/FormFields';
import { email, name, required } from 'components/FormFields/validations';
import {
  error as errorNotification,
  success as successNotification,
} from 'data/actions/notifications';
import { getCurrentRole, getSupportLoading } from 'data/reducers/support';
import { getCurrentUser, getEvent } from 'data/reducers';

import LoadingButton from 'components/LoadingButton';
import { Map } from 'immutable';
import { SupportFormData } from 'data/services/support';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';

type ContentSupportProps = {
  onCancel: () => void;
  handleSubmit: () => void;
  send: (supportFormData: SupportFormData) => Promise<{ error: boolean }>;
  loading: boolean;
  successNotification: (title: string, message: string) => void;
  errorNotification: (title: string, message: string) => void;
  currentRole: CurrentRole;
  event: { name: string };
};

const ContactSupport = ({
  onCancel,
  handleSubmit,
  send,
  loading,
  successNotification,
  errorNotification,
  currentRole,
  event,
}: ContentSupportProps) => {
  const curRole = currentRole?.role;

  async function onSubmit(values: Map<string, any>) {
    const formValues = values?.toJS();
    const response: { error: boolean } = await send({
      name: formValues.name,
      email: formValues.email,
      organization: formValues.organization,
      role: curRole || 'Role not relevant',
      event: event?.name || 'No event data available',
      url: window.location.href,
      message: formValues.message,
    });

    response.error
      ? errorNotification('Support message sent failed.', 'Error')
      : successNotification('Support message sent successfully.', 'Success');
    onCancel();
  }

  return (
    <Modal id={`contact-form-modal`} show onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Contact Support</Modal.Title>
      </Modal.Header>
      {/* @ts-ignore */}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row className="mt-3 mb-3">
            <Col>
              <Input
                // @ts-ignore
                className="m-0"
                id="name"
                name="name"
                placeholder="Name *"
                validate={[required, name]}
              />
            </Col>
            <Col>
              <Input
                // @ts-ignore
                className="m-0"
                id="organization"
                name="organization"
                placeholder="Organization"
              />
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col>
              <Email
                id="email"
                name="email"
                placeholder="Email *"
                className="m-0"
                validate={[required, email]}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {/* @ts-ignore */}
              <Textarea
                className="m-0"
                name="message"
                placeholder={'Message *'}
                rows="3"
                validate={[required]}
              />
            </Col>
            <Form.Text className="text-muted text-justify">
              Describe a detailed message of the support you need. Provide any error message that
              will assist us in resolving the problem.
            </Form.Text>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id={`contact-form-modal-cancel`}
            onClick={onCancel}
            variant={'link'}
            disabled={loading}
          >
            Cancel
          </Button>
          {/* @ts-ignore */}
          <LoadingButton
            type={'submit'}
            variant={'success'}
            id={`contact-form-modal-confirm`}
            disabled={loading}
            loading={loading}
          >
            Confirm
          </LoadingButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default connect(
  (state) => {
    const currentRole = getCurrentRole(state);
    const event = getEvent(state, currentRole?.eventId)?.toJS?.();
    const user = getCurrentUser(state)?.toJS?.();
    const loading = getSupportLoading(state);

    const initialValues = {
      name: user ? `${user.first_name} ${user.last_name}` : '',
      email: user ? user.email : '',
      organization: '',
      role: currentRole?.role,
      event: event?.id,
    };

    return {
      initialValues,
      currentRole,
      event,
      loading,
    };
  },
  { send, successNotification, errorNotification },
  // @ts-ignore
)(reduxForm({ form: 'ContactSupport', enableReinitialize: true })(ContactSupport));
