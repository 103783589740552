import { Col, Form, InputGroup, Row } from '@valid-eval/shared-react-components';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Date from 'components/Form/Date';
import { getEnvVar } from 'config';
import { Event } from 'data/features/eventsTypes';
import { DATE_FORMAT_WITH_TIME, humanize } from 'utils';

type GlobalSettingsDatesProps = {
  event: Event;
};

const GlobalSettingsDates = ({ event }: GlobalSettingsDatesProps) => {
  const { t } = useTranslation();
  const form = useFormContext();
  const email = getEnvVar('REACT_APP_SUPPORT_EMAIL', 'support@valideval.com');
  const currentTimezone = moment.tz.guess();
  const areDatesDisabled = !event?.start_date || !event?.end_date;
  const startDate = moment(event?.start_date).tz(currentTimezone);
  const endDate = moment(event?.end_date).tz(currentTimezone);

  const now = moment().tz(currentTimezone);

  const validteamSignupOpen = (value: string) => {
    if (areDatesDisabled || !value) return;

    const selectedDate = moment(value, DATE_FORMAT_WITH_TIME);
    const rawTeamSignupDeadline = form.getValues()?.teamSignupDeadline;
    const teamSignupDeadline =
      rawTeamSignupDeadline?.length > DATE_FORMAT_WITH_TIME.length
        ? moment(rawTeamSignupDeadline, DATE_FORMAT_WITH_TIME)
        : null;

    const isAfterStartDate = selectedDate.isSameOrAfter(startDate);
    const isBeforeEndDate = selectedDate.isSameOrBefore(endDate);
    const isBeforeTeamSignupDeadline = teamSignupDeadline?.isValid()
      ? selectedDate.isSameOrBefore(teamSignupDeadline)
      : true;

    if (!isAfterStartDate) {
      return t('settings.error.sign_up_open_less', {
        date: startDate.format(DATE_FORMAT_WITH_TIME),
        email,
      });
    }
    if (!isBeforeEndDate) {
      return t('settings.error.sign_up_open_greater', {
        date: endDate.format(DATE_FORMAT_WITH_TIME),
        email,
      });
    }
    if (!isBeforeTeamSignupDeadline && teamSignupDeadline) {
      return t('settings.error.sign_up_open_greater', {
        date: teamSignupDeadline.format(DATE_FORMAT_WITH_TIME),
        email,
      });
    }
    return;
  };

  const validTeamSignupDeadline = (value: string) => {
    if (areDatesDisabled || !value) return;

    const selectedDate = moment(value, DATE_FORMAT_WITH_TIME);
    const teamSignupOpen = moment(form.getValues()?.teamSignupOpen, DATE_FORMAT_WITH_TIME);

    const isAfterStartDate = selectedDate.isSameOrAfter(startDate);
    const isAfterteamSignupOpen = teamSignupOpen
      ? selectedDate.isSameOrAfter(teamSignupOpen)
      : true;
    const isBeforeEndDate = selectedDate.isSameOrBefore(endDate);

    if (!isAfterStartDate) {
      return t('settings.error.sign_up_deadline_less', {
        date: startDate.format(DATE_FORMAT_WITH_TIME),
        email,
      });
    }
    if (!isAfterteamSignupOpen && teamSignupOpen) {
      return t('settings.error.sign_up_deadline_less', {
        date: teamSignupOpen.format(DATE_FORMAT_WITH_TIME),
        email,
      });
    }
    if (!isBeforeEndDate) {
      return t('settings.error.sign_up_deadline_greater', {
        date: endDate.format(DATE_FORMAT_WITH_TIME),
        email,
      });
    }
    return;
  };

  const teamSignupOpen = moment(form.getValues()?.teamSignupOpen, DATE_FORMAT_WITH_TIME);
  const teamSignupDeadline = moment(form.getValues()?.teamSignupDeadline, DATE_FORMAT_WITH_TIME);

  const isSignupOpen =
    teamSignupOpen &&
    now.isSameOrAfter(teamSignupOpen) &&
    (!teamSignupDeadline || now.isSameOrBefore(teamSignupDeadline));
  const willSignupOpen = teamSignupOpen && now.isBefore(teamSignupOpen);

  return (
    <>
      <Row>
        <Col md="6" className="mb-4">
          <Form.Label htmlFor="teamSignupOpen">
            <b>Team Signup Opens</b>
          </Form.Label>
          <Date
            after={
              <InputGroup.Text className="overflow-hidden">
                <small className="text-truncate">
                  GMT{moment.tz(currentTimezone).format('Z')} - {humanize(currentTimezone)}
                </small>
              </InputGroup.Text>
            }
            disabled={areDatesDisabled}
            name="teamSignupOpen"
            rules={{ validate: { validteamSignupOpen } }}
            utc={false}
            isDateTime
          />
          <Form.Text className="text-muted">
            Date & time to activate the team application form.
          </Form.Text>
        </Col>
        <Col md="6" className="mb-4">
          <Form.Label htmlFor="teamSignupDeadline">
            <b>Team Signup Deadline</b>
          </Form.Label>
          <Date
            after={
              <InputGroup.Text className="overflow-hidden">
                <small className="text-truncate">
                  GMT{moment.tz(currentTimezone).format('Z')} - {humanize(currentTimezone)}
                </small>
              </InputGroup.Text>
            }
            disabled={areDatesDisabled}
            name="teamSignupDeadline"
            rules={{ validate: { validTeamSignupDeadline } }}
            utc={false}
            isDateTime
          />
          <Form.Text className="text-muted">Leave it empty for no deadline.</Form.Text>
        </Col>
      </Row>
      {areDatesDisabled && (
        <Row>
          <Col>
            <div className="alert alert-warning d-flex align-items-center justify-content-center">
              <i className="fas fa-exclamation-circle me-2 text-orange01 me-3" />
              <span>{t('settings.error.team_signup_window_disabled', { email })}</span>
            </div>
          </Col>
        </Row>
      )}
      {isSignupOpen && (
        <Row>
          <Col>
            <div className="alert alert-success d-flex align-items-center justify-content-center">
              <i className="fas fa-info-circle me-2 text-green01 me-3" />
              <span>{t('settings.alert.team_signup_open')}</span>
            </div>
          </Col>
        </Row>
      )}
      {!isSignupOpen && (
        <Row>
          <Col>
            <div className="alert alert-warning d-flex align-items-center justify-content-center">
              <i className="fas fa-info-circle me-2 text-orange01 me-3" />
              {willSignupOpen ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('settings.alert.team_signup_will_open', {
                      date: teamSignupOpen.format('MMMM Do YYYY, [at] h:mm:ss a [GMT]Z'),
                    }),
                  }}
                />
              ) : (
                <span>{t('settings.alert.team_signup_closed')}</span>
              )}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default GlobalSettingsDates;
