/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from '@valid-eval/shared-react-components';

const LinkedInButton = ({ login, text, className = '' }) => {
  return (
    <div className="d-grid">
      <Button href="#" variant="default" onClick={login}>
        <span className="fa-stack fa-2x me-2" style={{ fontSize: '0.7rem' }}>
          <i className="fa-solid fa-circle fa-stack-2x"></i>
          <i className="fa-brands fa-linkedin-in fa-stack-1x fa-inverse"></i>
        </span>
        <span>{text}</span>
      </Button>
    </div>
  );
};

LinkedInButton.displayName = 'LinkedInButton';
export default LinkedInButton;
