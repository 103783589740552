import { Form } from '@valid-eval/shared-react-components';
import { useRef } from 'react';

import Login from './Login';

const OauthNeedLogin = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const handleLoginSuccess = () => formRef.current?.submit();

  return (
    <>
      <Login oAuthNeedLogin onLoginSuccess={handleLoginSuccess} />
      <Form ref={formRef} action={'/api/v1/auth/link'} method="post" className="d-grid" />
    </>
  );
};

export default OauthNeedLogin;
