import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import orderBy from 'lodash/orderBy';

import { del, get, put } from 'data/services/config';

import { Notification } from './notificationsTypes';

export const NAME = 'notificationsSlice';
export const NOTIFICATIONS_CHANNEL_RECEIVED = 'socket/NotificationsChannel/received';

export const notificationsAdapter = createEntityAdapter<Notification>();

export const loadNotifications = createAsyncThunk(
  `${NAME}/load`,
  async ({ offset }: { offset: number }, { rejectWithValue }) => {
    try {
      const queryParams = new URLSearchParams({ offset: offset.toString() });
      return await get(`/notifications?${queryParams.toString()}`);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const toggleSeen = createAsyncThunk(
  `${NAME}/toggleSeen`,
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      return await put(`/notifications/${id}/toggle_seen`, {});
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const markAllAsSeen = createAsyncThunk(
  `${NAME}/markAllAsSeen`,
  async (_, { rejectWithValue }) => {
    try {
      return await put(`/notifications/mark_all_as_seen`, {});
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteNotification = createAsyncThunk(
  `${NAME}/delete`,
  async ({ id, batch }: { id: number; batch: boolean }, { rejectWithValue }) => {
    try {
      const queryParams = new URLSearchParams();
      if (batch) queryParams.set('batch', 'true');

      return await del(`/notifications/${id}?${queryParams.toString()}`, {});
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState = notificationsAdapter.getInitialState({
  saving: false,
  loading: false,
  offset: 0,
  totalCount: 0,
  unseenCount: 0,
  showList: false,
  listeners: {} as Record<string, (arg: any) => void>,
});

const notificationsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    openList(state) {
      state.showList = true;
    },
    closeList(state) {
      state.showList = false;
    },
    toggleList(state) {
      state.showList = !state.showList;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(markAllAsSeen.pending, (state) => {
        state.saving = true;
        const notifications = notificationsAdapter.getSelectors().selectAll(state);
        notificationsAdapter.setAll(
          state,
          notifications.map((notification) => ({ ...notification, seen: true })),
        );
        state.unseenCount = 0;
      })
      .addCase(markAllAsSeen.fulfilled, (state) => {
        state.saving = false;
      })
      .addCase(markAllAsSeen.rejected, (state) => {
        state.saving = false;
      })
      .addCase(toggleSeen.pending, (state, action) => {
        state.saving = true;
        const notification = notificationsAdapter
          .getSelectors()
          .selectById(state, action.meta.arg.id);
        if (notification) {
          notificationsAdapter.upsertOne(state, { ...notification, seen: !notification.seen });
        }
      })
      .addCase(toggleSeen.fulfilled, (state, action) => {
        state.saving = false;
        notificationsAdapter.upsertOne(state, action.payload.notification);
      })
      .addCase(toggleSeen.rejected, (state, action) => {
        state.saving = false;
        const notification = notificationsAdapter
          .getSelectors()
          .selectById(state, action.meta.arg.id);
        if (notification) {
          notificationsAdapter.upsertOne(state, { ...notification, seen: !notification.seen });
        }
      })
      .addCase(loadNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadNotifications.fulfilled, (state, action) => {
        notificationsAdapter.setMany(state, action.payload.notifications);
        state.offset = Number(action.payload.offset);
        state.totalCount = Number(action.payload.total_count);
        state.unseenCount = Number(action.payload.unseen_count);
      })
      .addCase(loadNotifications.rejected, (state) => {
        state.loading = false;
      })
      .addCase(NOTIFICATIONS_CHANNEL_RECEIVED, (state, action: any) => {
        const upsertNotification = () => {
          notificationsAdapter.upsertOne(state, action.payload.notification);
          state.offset = Number(action.payload.offset);
          state.totalCount = Number(action.payload.total_count);
          state.unseenCount = Number(action.payload.unseen_count);
        };

        switch (action.payload.operation) {
          case 'add':
          case 'seen':
            upsertNotification();
            break;
          case 'mark_all_seen':
            const notifications = notificationsAdapter.getSelectors().selectAll(state);
            notificationsAdapter.setAll(
              state,
              notifications.map((notification) => ({ ...notification, seen: true })),
            );
            state.unseenCount = 0;
            break;
          case 'remove':
            notificationsAdapter.removeOne(state, action.payload.notification.id);
            break;
        }
      })
      .addCase(deleteNotification.pending, (state) => {
        state.saving = true;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        notificationsAdapter.removeOne(state, action.meta.arg.id);
        state.saving = false;
      })
      .addCase(deleteNotification.rejected, (state) => {
        state.saving = false;
      });
  },
  selectors: {
    ...notificationsAdapter.getSelectors(),
    notifications: createSelector(notificationsAdapter.getSelectors().selectAll, (notifications) =>
      orderBy(notifications, ['seen', 'id'], ['asc', 'desc']),
    ),
    unseenCount: createSelector(
      notificationsAdapter.getSelectors().selectAll,
      (notifications) => notifications.filter((notification) => !notification.seen).length,
    ),
    totalCount: (state) => state.totalCount,
    offset: (state) => state.offset,
    showList: (state) => state.showList,
  },
});

export const { openList, closeList, toggleList } = notificationsSlice.actions;
export const fromNotifications = notificationsSlice.selectors;
export default notificationsSlice.reducer;
