import { Form, Tab, TabsGroup } from '@valid-eval/shared-react-components';
import GenericModal from 'components/GenericModal';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import withRouter from 'routes/withRouter';

import { error as showError } from 'data/actions/notifications';
import { scheduleAutomatch } from 'data/actions/phases';
import { Phase } from 'data/features/phaseTypes';
import { getSelectedPhase } from 'data/reducers';
import { baseCdnUrlFor } from 'utils';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import { fromAutomatch, loadAutomatchInfo } from 'data/features/automatch';
import { Map } from 'immutable';
import FormulaChoice, { FormulaValues } from './FormulaChoice';
import Loading from 'components/Loading';

type MatchOption = 'teamsPerJudge' | 'judgesPerTeam';

type OwnProps = {
  close: () => void;
  show: boolean;
};

const connector = connect(
  (state: Map<string, any>, ownProps) => ({
    phase: getSelectedPhase(state, ownProps)?.toJS() as Phase | undefined,
    automatchInfo: fromAutomatch.getAutomatchInfo(state?.toJS()),
    isLoading: fromAutomatch.getIsLoading(state?.toJS()),
  }),
  {
    showError,
    scheduleAutomatch,
    loadAutomatchInfo,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type AssigmentsAutoMatchModalProps = PropsFromRedux & OwnProps;

const AssigmentsAutoMatchModal = ({
  phase,
  show,
  close,
  showError,
  scheduleAutomatch,
  automatchInfo,
  isLoading,
  loadAutomatchInfo,
}: AssigmentsAutoMatchModalProps) => {
  const { t } = useTranslation();

  const [isSchedulingAutomatch, startSchedulingAutomatch, finishSchedulingAutomatch] =
    useBooleanFlag(false);
  const [teamsPerJudge, setTeamsPerJudge] = useState('');
  const [judgesPerTeam, setJudgesPerTeam] = useState('');
  const [selectedOption, setSelectedOption] = useState<MatchOption>('teamsPerJudge');

  const minTeamsPerJudge = automatchInfo?.min_teams_assigned_per_judge || 0;
  const minJudgesPerTeam = automatchInfo?.min_judges_assigned_per_team || 0;
  const activeJudges = automatchInfo?.active_judges || 0;
  const activeTeams = automatchInfo?.active_teams || 0;
  const autoMatchTeamsPerJudge = automatchInfo?.auto_match_teams_per_judge || 0;
  const autoMatchJudgesPerTeam = automatchInfo?.auto_match_judges_per_team || 0;

  // Load automatch info when the modal is shown
  useEffect(() => {
    if (phase && show) loadAutomatchInfo(phase.id);
  }, [show, phase?.id]);

  useEffect(() => {
    if (phase?.id && automatchInfo) {
      setTeamsPerJudge(autoMatchTeamsPerJudge.toString());
      setJudgesPerTeam(autoMatchJudgesPerTeam.toString());
    }
  }, [phase?.id, automatchInfo]);

  const formulaValues: FormulaValues = useMemo(() => {
    const teamsPerJudgeInt = parseInt(teamsPerJudge) || 0;
    const judgesPerTeamInt = parseInt(judgesPerTeam) || 0;

    const averageJudgesPerTeam =
      selectedOption === 'teamsPerJudge' && activeTeams && activeJudges && teamsPerJudgeInt
        ? Math.floor((teamsPerJudgeInt * activeJudges) / activeTeams)
        : judgesPerTeamInt;

    const averageTeamsPerJudge =
      selectedOption === 'judgesPerTeam' && activeTeams && activeJudges && judgesPerTeamInt
        ? Math.ceil((judgesPerTeamInt * activeTeams) / activeJudges)
        : teamsPerJudgeInt;

    return {
      teamsPerJudge: teamsPerJudgeInt,
      judgesPerTeam: judgesPerTeamInt,
      activeJudges,
      activeTeams,
      averageJudgesPerTeam,
      averageTeamsPerJudge,
    };
  }, [phase, teamsPerJudge, judgesPerTeam, selectedOption, automatchInfo]);

  const handleAutoMatch = async () => {
    if (!phase) return;

    startSchedulingAutomatch();
    try {
      const payload =
        selectedOption === 'teamsPerJudge'
          ? { is_panel_match: false, teams_per_judge: formulaValues.teamsPerJudge }
          : { is_panel_match: false, judges_per_team: formulaValues.judgesPerTeam };
      const res = await scheduleAutomatch(phase.id, payload);
      if ('error' in res && typeof res.error === 'string') {
        throw new Error(res.error);
      }
    } catch (e) {
      showError('Failed to start automatch.');
    } finally {
      finishSchedulingAutomatch();
      close();
    }
  };

  const isButtonDisabled =
    isSchedulingAutomatch ||
    isLoading ||
    (selectedOption === 'teamsPerJudge' &&
      (!formulaValues.teamsPerJudge ||
        formulaValues.teamsPerJudge > formulaValues.activeTeams ||
        minTeamsPerJudge >= formulaValues.teamsPerJudge)) ||
    (selectedOption === 'judgesPerTeam' &&
      (!judgesPerTeam ||
        judgesPerTeam === '0' ||
        formulaValues.judgesPerTeam > formulaValues.activeJudges ||
        minJudgesPerTeam >= formulaValues.judgesPerTeam));

  return (
    <GenericModal
      show={show}
      onCancel={close}
      onConfirm={handleAutoMatch}
      name="auto-match"
      title={t('automatch.title')}
      body={
        isLoading ? (
          <Loading />
        ) : (
          <>
            <p className="fs-6 fw-bold text-center">{t('automatch.subtitle')}</p>
            <Form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                handleAutoMatch();
              }}
            >
              <TabsGroup
                activeKey={selectedOption}
                onSelect={(key) => setSelectedOption(key as MatchOption)}
                className="d-flex justify-content-center"
              >
                <Tab eventKey="teamsPerJudge" title={t('automatch.teamsPerJudgeButton')}>
                  {formulaValues.teamsPerJudge > 0 &&
                    (formulaValues.teamsPerJudge > formulaValues.activeTeams ||
                      minTeamsPerJudge >= formulaValues.teamsPerJudge) && (
                      <div className="alert alert-warning p-3 d-flex align-items-center justify-content-center">
                        <i className="fa-solid fa-circle-exclamation text-orange01 me-2" />
                        <span>
                          {formulaValues.teamsPerJudge > formulaValues.activeTeams &&
                            t('automatch.error.teams_per_judge.not_enough')}
                          {minTeamsPerJudge >= formulaValues.teamsPerJudge &&
                            t('automatch.error.teams_per_judge.exceeded')}
                        </span>
                      </div>
                    )}
                  <div className="d-flex flex-column align-items-center">
                    <FormulaChoice
                      selectedOption={selectedOption}
                      formulaValues={formulaValues}
                      teamsPerJudge={teamsPerJudge}
                      setTeamsPerJudge={setTeamsPerJudge}
                      judgesPerTeam={judgesPerTeam}
                      setJudgesPerTeam={setJudgesPerTeam}
                    />
                  </div>
                </Tab>
                <Tab eventKey="judgesPerTeam" title={t('automatch.judgesPerTeamButton')}>
                  {formulaValues.judgesPerTeam > 0 &&
                    (formulaValues.judgesPerTeam > formulaValues.activeJudges ||
                      minJudgesPerTeam >= formulaValues.judgesPerTeam) && (
                      <div className="alert alert-warning p-3 d-flex align-items-center justify-content-center">
                        <i className="fa-solid fa-circle-exclamation text-orange01 me-2" />
                        <span>
                          {formulaValues.judgesPerTeam > formulaValues.activeJudges &&
                            t('automatch.error.judges_per_team.not_enough')}
                          {minJudgesPerTeam >= formulaValues.judgesPerTeam &&
                            t('automatch.error.judges_per_team.exceeded')}
                        </span>
                      </div>
                    )}
                  <div className="d-flex flex-column align-items-center">
                    <FormulaChoice
                      selectedOption={selectedOption}
                      formulaValues={formulaValues}
                      teamsPerJudge={teamsPerJudge}
                      setTeamsPerJudge={setTeamsPerJudge}
                      judgesPerTeam={judgesPerTeam}
                      setJudgesPerTeam={setJudgesPerTeam}
                    />
                  </div>
                </Tab>
              </TabsGroup>
              <Form.Text className="text-muted mt-3">
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(
                      selectedOption === 'teamsPerJudge'
                        ? 'automatch.teamsPerJudgeGuidance'
                        : 'automatch.judgesPerTeamGuidance',
                      {
                        spreadsheetLink: baseCdnUrlFor(
                          selectedOption === 'teamsPerJudge'
                            ? 'help-content/AutoMatch_Calc_Worksheet_v3.xlsx'
                            : 'help-content/AutoMatch_Calc_Worksheet_Juges_Per_Team_v3.xlsx',
                        ),
                      },
                    ),
                  }}
                />
              </Form.Text>
            </Form>
          </>
        )
      }
      cancelButton={t('common.cancel')}
      confirmButton={t('automatch.confirmButton')}
      isButtonDisabled={isButtonDisabled}
      isCancelButtonDisabled={isSchedulingAutomatch}
    />
  );
};

export default withRouter(connector(AssigmentsAutoMatchModal));
