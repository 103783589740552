import { Button, OverlayTrigger, Popover } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { Map } from 'immutable';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { connect, ConnectedProps } from 'react-redux';

import Loading from 'components/Loading';
import { getEnvVar } from 'config';
import { error as showError } from 'data/actions/notifications';
import {
  closeList,
  fromNotifications,
  loadNotifications,
  markAllAsSeen,
  openList,
  toggleList,
  toggleSeen,
} from 'data/features/notifications';
import { Channel, fromSocket, joinChannel, leaveChannel } from 'data/features/socket';
import { getText } from 'utils/notifications/automatch';
import tabUtils from 'utils/tab_utils';

import { openReportURL } from './helpers';
import Styles from './NotificationsList.module.scss';
import NotificationItem from './NotificationItem';

type OwnProps = {};

const mapStateToProps = (state: Map<string, any>) => ({
  socketReady: fromSocket.getReady(state.toJS()),
  notifications: fromNotifications.notifications(state.toJS()),
  offset: fromNotifications.offset(state.toJS()),
  totalCount: fromNotifications.totalCount(state.toJS()),
  unseenCount: fromNotifications.unseenCount(state.toJS()),
  showList: fromNotifications.showList(state.toJS()),
});

const mapDispatchToProps = {
  closeList,
  joinChannel,
  leaveChannel,
  loadNotifications,
  markAllAsSeen,
  openList,
  showError,
  toggleList,
  toggleSeen,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type NotificationsListProps = ConnectedProps<typeof connector> & OwnProps;

const NotificationsListEmptyState = () => {
  return (
    <li>
      <div className="stack-list-text">
        <div className="bold">No notifications</div>
      </div>
    </li>
  );
};

const NotificationsList = ({
  closeList,
  joinChannel,
  leaveChannel,
  loadNotifications,
  markAllAsSeen,
  notifications,
  offset,
  openList,
  showError,
  showList,
  socketReady,
  toggleList,
  toggleSeen,
  totalCount,
  unseenCount,
}: NotificationsListProps) => {
  const { t } = useTranslation();
  const loadMore = () => loadNotifications({ offset });

  useEffect(() => {
    loadMore();
  }, []);

  const handleNotificationsChannelEvent = (payload: any) => {
    const operation = payload.operation;
    if (operation === 'add') {
      const notification = payload.notification || {};
      const { action, data } = notification;

      // Handle new report notification
      if (
        action === 'report_generated' ||
        ((action === 'panel_match' || action === 'automatch') &&
          (data?.success || data?.is_running)) ||
        action === 'team_survey'
      ) {
        openList();
      }

      // Report completed
      if (
        action === 'report_generated' &&
        data?.report_status === 'COMPLETED' &&
        data?.report_type !== 'team_files_report'
      ) {
        tabUtils.callOnce(
          `openReportUrlLock-${notification.id}`,
          () => {
            openReportURL(
              notification.url,
              data.report_type === 'pdf_results' || data.report_type === 'artifact_pdf_team_report',
              () => showError(t('blocked_popup_error', { url: getEnvVar('REACT_APP_HOST') })),
            );
          },
          5e3,
        );
      }

      // Auto match or panel match failed
      if (
        (action === 'automatch' || action === 'panel_match') &&
        !data?.success &&
        !data?.is_running
      ) {
        showError(getText(notification));
        closeList();
      }
    }
  };

  useEffect(() => {
    if (!socketReady) return;

    const listener = joinChannel({
      channel: Channel.Notifications,
      listener: handleNotificationsChannelEvent,
    })?.payload.id;

    return () => {
      leaveChannel({ channel: Channel.Notifications, listener });
    };
  }, [socketReady]);

  return (
    <OverlayTrigger
      onToggle={() => toggleList()}
      show={showList}
      trigger="click"
      overlay={
        <Popover id="notifications-popover" className={cx(Styles.Popover, 'd-print-none')}>
          <ul className={cx(Styles.StackList, 'notification stack-list')}>
            {notifications.length === 0 && <NotificationsListEmptyState />}
            {notifications.length > 0 && (
              <InfiniteScroll
                pageStart={offset || 0}
                hasMore={totalCount > notifications.length}
                loadMore={loadMore}
                loader={<Loading key={0} />}
                useWindow={false}
              >
                {notifications.map((notification) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    onCloseList={closeList}
                    onToggleSeen={toggleSeen}
                  />
                ))}
              </InfiniteScroll>
            )}
          </ul>
          {unseenCount > 0 && (
            <Button
              className={Styles.MarkAsReadButton}
              variant="link"
              id="notifications-mark-all-as-read"
              onClick={markAllAsSeen}
            >
              Mark all as read
            </Button>
          )}
        </Popover>
      }
      placement="bottom"
      rootClose
    >
      <div id="notifications-popover" role="button">
        <span
          className={cx({
            [Styles.NotificationTitleDown]: !showList,
            [Styles.NotificationTitleUp]: showList,
          })}
        >
          Notifications
          {unseenCount > 0 && <span className={cx(Styles.NotificationBadge, Styles.Badge)} />}
          {showList && <i className={cx(Styles.NotificationArrowUp, 'fa-solid fa-angle-up')} />}
          {!showList && (
            <i className={cx(Styles.NotificationArrowDown, 'fa-solid fa-angle-down')} />
          )}
        </span>
      </div>
    </OverlayTrigger>
  );
};

export default connector(NotificationsList);
