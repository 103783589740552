import { Consumer, createConsumer, Subscription } from '@rails/actioncable';
import { PayloadAction } from '@reduxjs/toolkit';
import { Middleware, MiddlewareAPI } from 'redux';

import { getEnvVar } from 'config';
import { initSocket, joinChannel, leaveChannel, ready } from 'data/features/socket';

let cable: Consumer | undefined;
const channels: Record<string, Subscription<Consumer>> = {};
const listeners: Record<string, (payload: any) => void> = {};

const initSocketMethod = (store: MiddlewareAPI) => {
  cable = createConsumer(getEnvVar('REACT_APP_WS_URL', `ws://localhost:3000/api/v1/cable`));
  store.dispatch(ready());
};

const joinChannelMethod = (
  store: MiddlewareAPI,
  action: PayloadAction<{
    id: string;
    channel: string;
    params?: Record<string, any>;
    listener?: (payload: any) => void;
  }>,
) => {
  // unsubscribe from the channel if it already exists
  if (channels[action.payload.channel]) {
    channels[action.payload.channel].unsubscribe();
    delete channels[action.payload.channel];
  }

  if (action.payload.listener) {
    listeners[`${action.payload.channel}-${action.payload.id}`] = action.payload.listener;
  }

  // subscribe to the channel
  const channel = cable?.subscriptions.create(
    {
      channel: action.payload.channel,
      ...action.payload.params,
    },
    {
      connected() {
        store.dispatch({ type: `socket/${action.payload.channel}/connected` });
      },
      disconnected() {
        store.dispatch({ type: `socket/${action.payload.channel}/disconnected` });
      },
      received(data) {
        store.dispatch({ type: `socket/${action.payload.channel}/received`, payload: data });
        Object.keys(listeners).forEach((key) => {
          if (key.includes(action.payload.channel)) {
            listeners[key](data);
          }
        });
      },
    },
  );
  if (channel) channels[action.payload.channel] = channel;
};

const leaveChannelMethod = (action: PayloadAction<{ channel: string }>) => {
  if (channels[action.payload.channel]) {
    // unsubscribe from the channel
    channels[action.payload.channel].unsubscribe();
    delete channels[action.payload.channel];

    // delete the listener
    Object.keys(listeners).forEach((key) => {
      if (key.includes(action.payload.channel)) {
        delete listeners[key];
      }
    });
  }
};

export const handleSocket: Middleware = (store) => (next) => (action) => {
  switch (true) {
    case initSocket.match(action):
      initSocketMethod(store);
      break;
    case joinChannel.match(action):
      joinChannelMethod(store, action);
      break;
    case leaveChannel.match(action):
      leaveChannelMethod(action);
      break;
  }

  return next(action);
};
