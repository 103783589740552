import { Button, Form } from '@valid-eval/shared-react-components';
import parse from 'html-react-parser';
import { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form/immutable';

import { Input, Password } from 'components/FormFields';
import { getEnvVar } from 'config';

import Errors from '../../components/Errors';

const LoginForm = ({ handleSubmit, error, submitting, showOtpField, showPasswordField }) => {
  const errors = error && [error];
  const { t } = useTranslation();
  const email = getEnvVar('REACT_APP_SUPPORT_EMAIL', 'support@valideval.com');
  const passwordRef = useRef(null);
  const otpRef = useRef(null);

  useLayoutEffect(() => {
    if (passwordRef.current?.ref?.current?.ref?.current && showPasswordField) {
      passwordRef.current?.ref?.current?.ref?.current?.focus();
    }
    if (otpRef.current?.ref?.current?.ref?.current && showOtpField) {
      otpRef.current?.ref?.current?.ref?.current?.focus();
    }
  }, [showPasswordField, showOtpField]);

  let submitLabel = showPasswordField
    ? t('auth.login.enter', 'Enter')
    : t('common.continue', 'Continue');
  submitLabel = showOtpField ? t('auth.login.login_with_mfa', 'Login with MFA') : submitLabel;

  return (
    <div>
      <Errors errors={errors} alertClasses={{ alert: 'alert alert-danger alert-inline' }} />
      <Form onSubmit={handleSubmit}>
        <div className="login-input">
          <Input label={t('auth.login.username', 'Email Address')} name="username" />
        </div>
        {showPasswordField && (
          <div className="login-input">
            <Password
              ref={passwordRef}
              label={t('auth.login.password', 'Password')}
              name="password"
            />
          </div>
        )}
        {showOtpField && (
          <div className="login-input">
            <Input
              ref={otpRef}
              name="otp"
              label={t('auth.login.otp', 'MFA Code')}
              autoComplete={'off'}
            />
          </div>
        )}
        <div className="text-end mt-3">
          <Link className="login-link" to="/forgot-password">
            {t('auth.login.forgot_password', 'Forgot your password?')}
          </Link>
        </div>
        <div className="mt-4 d-grid">
          <Button id="login-button" variant="success" type="submit" disabled={submitting}>
            {submitLabel}
          </Button>
        </div>
        {showOtpField && (
          <p className="mt-3 mb-0 p-2 rounded bg-dark-transparent">
            <small>{parse(t('auth.login.otp_description', { email }))}</small>
          </p>
        )}
      </Form>
    </div>
  );
};

export default reduxForm({ form: 'login' })(LoginForm);
