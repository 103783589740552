import { Button } from '@valid-eval/shared-react-components';
import { Idp } from 'data/features/oauthConfigurationTypes';
import { useEffect, useRef } from 'react';

type OauthButtonFormProps = {
  idp: Idp;
  autoSubmit?: boolean;
  signUp?: boolean;
};

export const findIdp = (idps: Idp[], email: string) => {
  return idps.find((idp) => idp.email_domains.some((domain) => email.endsWith(domain)));
};

const OauthButtonForm = ({ idp, autoSubmit = false, signUp = false }: OauthButtonFormProps) => {
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (autoSubmit) formRef.current?.submit();
  }, [autoSubmit]);

  return (
    <form ref={formRef} className="d-grid" action={idp.path} method="post">
      {!!idp.kc_idp_hint && <input type="hidden" name="kc_idp_hint" value={idp.kc_idp_hint} />}
      {!autoSubmit && (
        <Button
          variant="default"
          type="submit"
          className="d-flex align-items-center justify-content-center"
        >
          <span className="fa-stack fa-2x me-2" style={{ fontSize: '0.7rem' }}>
            <i className="fa-solid fa-circle fa-stack-2x"></i>
            <i className={`${idp.icon} fa-stack-1x fa-inverse`}></i>
          </span>
          <span>
            {idp.name} {signUp ? 'Sign Up' : 'Login'}
          </span>
        </Button>
      )}
    </form>
  );
};

export default OauthButtonForm;
