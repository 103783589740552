import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { get } from 'data/services/config';

const initialState: {
  loadingInfo: boolean;
  automatchInfo: {
    active_teams: number;
    active_judges: number;
    auto_match_judges_per_team: number;
    auto_match_teams_per_judge: number;
    max_judges_assigned_per_team: number;
    min_judges_assigned_per_team: number;
    max_teams_assigned_per_judge: number;
    min_teams_assigned_per_judge: number;
  } | null;
} = {
  loadingInfo: false,
  automatchInfo: null,
};

export const loadAutomatchInfo = createAsyncThunk(
  'automatch/loadInfo',
  async (phaseId: string, { rejectWithValue }) => {
    try {
      return await get(`phases/${phaseId}/automatch_info`);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const automatchSlice = createSlice({
  name: 'automatchSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadAutomatchInfo.fulfilled, (state, action) => {
        state.automatchInfo = action.payload.automatch_info;
        state.loadingInfo = false;
      })
      .addCase(loadAutomatchInfo.pending, (state) => {
        state.loadingInfo = true;
      })
      .addCase(loadAutomatchInfo.rejected, (state) => {
        state.loadingInfo = false;
      });
  },
  selectors: {
    getAutomatchInfo: (state) => state.automatchInfo,
    getIsLoading: (state) => state.loadingInfo,
  },
});

export const fromAutomatch = automatchSlice.selectors;
export default automatchSlice.reducer;
