import { success } from 'data/actions/notifications';
import { forgotPassword } from 'data/actions/users';
import { useState } from 'react';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form/immutable';
import { getVersionUri } from 'utils';
import Briefcase from './assets/briefcase.png';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import ErrorBoundary from './ErrorBoundary';

const ForgotPassword = ({ forgotPassword, success }) => {
  const [lastSuccessfulRequest, setSuccessfulRequest] = useState();

  const onSubmit = async (data) => {
    const result = await forgotPassword(data.get('email'));

    if (result.error) {
      const {
        response: { error: errorMessage },
      } = result.payload;
      throw new SubmissionError({ _error: errorMessage });
    } else {
      const { message } = result.payload;
      success(message);
      setSuccessfulRequest(data.get('email'));
    }
  };

  return (
    <ErrorBoundary>
      <div className="container">
        <div className="login-container mb-5">
          <h1 className="login-title mt-3 mb-3">Forgot password?</h1>
          <div className="login-box d-flex flex-column">
            <h2 className="login-subtitle mb-4">{getVersionUri()}</h2>
            <div style={{ flex: 1 }}>
              <ForgotPasswordForm onSubmit={onSubmit} lastEmailRequested={lastSuccessfulRequest} />
            </div>
            <div className="text-center mt-4 mb-2">
              <img src={Briefcase} className="login-footer-image" alt="Briefcase little icon" />
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

ForgotPassword.displayName = 'ForgotPassword';
export default connect(null, { forgotPassword, success })(ForgotPassword);
