import React from 'react';
import cx from 'classnames';
import styles from './Loading.module.scss';

const Loading = ({ text = '' }) => (
  <div className={cx(styles.container)}>
    {text && <span className="me-2">{text}&nbsp;&nbsp;</span>}
    <i className={cx('fa-duotone fa-spinner fa-spin fa-2x', styles.faPulse)} />
  </div>
);

export const spinnerWhileLoading = (hasLoaded) => (WrappedComponent) => {
  return function WithSpinnerComponent(props) {
    if (!hasLoaded(props)) {
      return <Loading />;
    }
    return <WrappedComponent {...props} />;
  };
};

export default Loading;
