import { Button } from '@valid-eval/shared-react-components';
import { Notification } from './types';

interface AutomatchNotification extends Notification {}

export const getActionButtons = (notification: AutomatchNotification): Array<any> => {
  const { is_running } = notification.data;

  if (is_running) {
    return [
      () => (
        <Button variant="link" disabled>
          <i className="fa-duotone fa-spinner fa-lg fa-spin"></i> Running auto-match
        </Button>
      ),
    ];
  }
  return [];
};

export const getText = (notification: AutomatchNotification): string => {
  const { is_running, success, error, phase_name, event_name } = notification.data;
  if (is_running) {
    return `Auto-match is running for ${event_name} cohort at phase ${phase_name}`;
  } else if (success) {
    return `Auto-match completed for ${event_name} cohort at phase ${phase_name}`;
  } else {
    return `Auto-match failed for ${event_name} cohort at phase ${phase_name}. ${error}`;
  }
};
