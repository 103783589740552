import { Form } from '@valid-eval/shared-react-components';
import { FileUpload } from '../../components/FormFields';

import { reduxForm } from 'redux-form/immutable';

const FileField = ({
  eventId,
  teamId,
  id,
  name,
  title,
  instructions,
  disabled,
  acceptedFileType,
  onDropFile,
  current,
  fileExtension,
  disableDelete,
}) => {
  return (
    <Form>
      <FileUpload
        eventId={eventId}
        teamId={teamId}
        acceptedFileType={acceptedFileType}
        onDropFile={onDropFile}
        disabled={disabled}
        current={current}
        id={id}
        name={name}
        title={title || name}
        helpText={instructions}
        fileExtension={fileExtension}
        disableDelete={disableDelete}
      />
    </Form>
  );
};

export default reduxForm({ form: 'team_file_field' })(FileField);
