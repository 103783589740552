import { Form, FormGroup } from '@valid-eval/shared-react-components';
import { required, url } from '../../components/FormFields/validations';

import LoadingButton from '../LoadingButton';

import { reduxForm } from 'redux-form/immutable';
import { Url } from '../../components/FormFields';

const isRequiredUrl = [required, url];
const isUrl = [url];

const UrlField = ({
  handleSubmit,
  submitting,
  title,
  id,
  name,
  instructions,
  isRequired,
  disabled,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup className="mt-3 mb-3">
        <Url
          id={id}
          name={name}
          title={title || name}
          disabled={disabled}
          helpText={instructions}
          validate={isRequired ? isRequiredUrl : isUrl}
        />
        <div className="mt-3 mb-3">
          <LoadingButton
            loading={submitting}
            disabled={submitting || disabled}
            variant="primary"
            type="submit"
          >
            Replace URL
          </LoadingButton>
        </div>
      </FormGroup>
    </Form>
  );
};

export default reduxForm({ form: 'team_url_field', enableReinitialize: true })(UrlField);
