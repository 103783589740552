import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import uuid from 'uuid-random';

export enum Channel {
  Notifications = 'NotificationsChannel',
  Phases = 'PhasesChannel',
}

const initialState = {
  ready: false,
};

const socketSlice = createSlice({
  name: 'socketSlice',
  initialState: initialState,
  reducers: {
    initSocket() {},

    joinChannel: {
      prepare({
        channel,
        params,
        listener,
      }: {
        channel: Channel;
        params?: Record<string, any>;
        listener?: (payload: any) => void;
      }) {
        return {
          payload: {
            id: uuid(),
            channel,
            params,
            listener,
          },
        };
      },
      reducer(
        _,
        __: PayloadAction<{
          id: string;
          channel: Channel;
          params?: Record<string, any>;
          listener?: (payload: any) => void;
        }>,
      ) {},
    },

    leaveChannel(_, __: { payload: { channel: Channel; listener?: string } }) {},
    ready(state) {
      state.ready = true;
    },
  },
  selectors: {
    getReady: (state) => state.ready,
  },
});

export const { initSocket, ready, joinChannel, leaveChannel } = socketSlice.actions;
export const fromSocket = socketSlice.selectors;
export default socketSlice.reducer;
