import { Button, ButtonGroup } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import CompareTeamsNotes from 'components/NotesV2/CompareTeamsNotes';
import { update } from 'data/actions/evaluations';
import {
  getEvent,
  getIsLoadingEvaluations,
  getPhase,
  getPhaseNavigatorsViewResultsEnabled,
  getPhaseSummary,
  getResultsForSelectedPhase,
  getRubric,
  getScoresByEvaluation,
  getSelectedPhaseId,
} from 'data/reducers';
import withRouter from 'routes/withRouter';
import { isNavigator, isTeamLead } from 'utils';
import { organizerNav } from 'utils/urls';

import CompareScoreSummaryTable from './components/CompareScoreSummaryTable';
import JudgeScoresModal from './components/JudgeScoresModal';
import ResultsNav from './components/ResultsNav';
import TableStyles from './components/ResultsTable/components/ResultsTable.module.scss';
import SigmasByJudge from './components/SigmasByJudge';
import TeamCompareSelector from './components/TeamCompareSelector';
import TeamStatusStats from './components/TeamStatusStats';
import { getScoreSigmas, getTeamSelectOption, getTeamsSelectOptions } from './helpers';
import { usePhaseData, useTeamData } from './hooks';
import StaleScoresAlert from './components/StaleScoresAlert';
import PhasesListener from './components/PhaseBuildStatisticsListener';

function ResultsCompare({
  event,
  update,
  navigate,
  teamIds,
  evaluations,
  phase,
  phaseSummary,
  rubric,
  teamA,
  teamB,
  teamC,
  canAccessResults,
  query,
}) {
  const loadPhaseData = usePhaseData(phase);
  const loadTeamAData = useTeamData(teamA, phase);
  const loadTeamBData = useTeamData(teamB, phase);
  const loadTeamCData = useTeamData(teamC, phase);
  const { t } = useTranslation();
  const [isZScore, setIsZScore] = useState(true);
  const [judge, setJudge] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const isExpandedFromUrl = query.isExpanded === 'true';
  const [isExpanded, , , toggleExpanded] = useBooleanFlag(isExpandedFromUrl);
  const isTeamLeadAndQualitativeMode =
    isTeamLead() && event?.judging_ux_mode === 'qualitative_only_mode';

  const reloadData = () => {
    loadPhaseData();
    loadTeamAData(teamA?.id);
    loadTeamBData(teamB?.id);
    loadTeamCData(teamC?.id);
  };

  const handleSelectTeam = (letter, team) => {
    const id = team?.id || '';
    const ids = {
      teamAId: teamIds[0],
      teamBId: teamIds[1],
      teamCId: teamIds[2],
      [`team${letter}Id`]: id, // Replace the id defined before
    };
    const selectedEvaluationIds = Object.values(ids).join(',');
    const url = organizerNav.compareTeams(event.id, selectedEvaluationIds);
    navigate(url);
  };

  const makeHandleSelectStatus = (teamId) => (status) => teamId && update(teamId, status);

  async function handleSigmaClick(judge) {
    setIsModalOpened(true);
    setJudge(judge);
  }

  function handleHideModal() {
    setIsModalOpened(false);
    setJudge(null);
  }

  if (!canAccessResults) return <Navigate to={organizerNav.status(event.id)} />;

  return (
    !!rubric &&
    !!evaluations &&
    !!phaseSummary && (
      <>
        <PhasesListener
          phaseId={phase?.id}
          onUpdate={({ hasStaleScores }) => {
            if (!hasStaleScores) reloadData();
          }}
        />
        <StaleScoresAlert show={phase?.has_stale_scores} />
        <div style={{ minWidth: 1000 }}>
          <JudgeScoresModal
            judge={judge}
            eventId={event.id}
            teamAId={teamA?.team}
            teamBId={teamB?.team}
            teamCId={teamC?.team}
            isOpen={isModalOpened}
            onHide={handleHideModal}
          />
          <ResultsNav eventId={event.id} compare />
          <h1 className="mb-3 mt-5">{phase?.name} Comparison</h1>
          <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
            <div className={cx('me-3')}>
              <ButtonGroup aria-label="Switch between Valid Rank&trade; and Valid Sigmas&trade;">
                <Button
                  data-test-id="compare-teams-z-score-view"
                  variant={isZScore ? 'primary' : 'default'}
                  onClick={() => setIsZScore(true)}
                >
                  Z Score
                </Button>
                {!isTeamLeadAndQualitativeMode && (
                  <Button
                    data-test-id="compare-teams-ranking-view"
                    variant={isZScore ? 'default' : 'primary'}
                    onClick={() => setIsZScore(false)}
                  >
                    Ranking
                  </Button>
                )}
              </ButtonGroup>
            </div>
            <div className={cx('col-auto d-flex', TableStyles.DescriptionSwitch)}>
              <div className="d-flex align-items-center">
                <span className="me-2">Show description</span>
                <label className={TableStyles.Switch}>
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={isExpanded}
                    onChange={toggleExpanded}
                  />
                  <span className={'slider'} />
                </label>
              </div>
            </div>
            {/* <div>
            <Button variant={'link'} className="p-0" onClick={handlePrint}>
              <i className="fa-solid fa-print fa-lg" />
              Export PDF
            </Button>
          </div> */}
          </div>

          {isZScore && !isTeamLeadAndQualitativeMode && (
            <div className="mt-4">
              <CompareScoreSummaryTable
                dimensions={rubric.dimensions}
                phaseSummary={phaseSummary}
                teamA={teamA}
                teamB={teamB}
                teamC={teamC}
              />
            </div>
          )}
          <div className="mt-4">
            <TeamCompareSelector
              isZScore={isZScore}
              onSelectTeam={handleSelectTeam}
              teamA={getTeamSelectOption(teamA)}
              teamB={getTeamSelectOption(teamB)}
              teamC={getTeamSelectOption(teamC)}
              teams={getTeamsSelectOptions(evaluations)}
              eventId={event.id}
              phaseId={phase.id}
              phase={phase}
            />
          </div>
          <div className="d-flex flex-row">
            <div style={{ flex: 1 }}>
              <TeamStatusStats
                event={event}
                finalRankEnabled={phase.final_rank}
                finalRank={teamA?.final_rank}
                teamId={teamA?.team}
                isZScore={isZScore}
                onSelect={makeHandleSelectStatus(teamA?.id)}
                status={teamA?.status}
                evaluations={teamA?.score_count}
                rankStrength={teamA?.rank_strength}
                agreementStrength={teamA?.valid_score_agreement_strength}
                validScore={teamA?.valid_score}
              />
            </div>
            <div style={{ flex: 1, margin: '0 12px 0 12px' }}>
              <TeamStatusStats
                event={event}
                finalRankEnabled={phase.final_rank}
                finalRank={teamB?.final_rank}
                teamId={teamB?.team}
                isZScore={isZScore}
                onSelect={makeHandleSelectStatus(teamB?.id)}
                status={teamB?.status}
                evaluations={teamB?.score_count}
                rankStrength={teamB?.rank_strength}
                agreementStrength={teamB?.valid_score_agreement_strength}
                validScore={teamB?.valid_score}
              />
            </div>
            <div style={{ flex: 1 }}>
              <TeamStatusStats
                event={event}
                finalRankEnabled={phase.final_rank}
                finalRank={teamC?.final_rank}
                teamId={teamC?.team}
                isZScore={isZScore}
                onSelect={makeHandleSelectStatus(teamC?.id)}
                status={teamC?.status}
                evaluations={teamC?.score_count}
                rankStrength={teamC?.rank_strength}
                agreementStrength={teamC?.valid_score_agreement_strength}
                validScore={teamC?.valid_score}
              />
            </div>
          </div>
          <h2 className="mt-4 mb-4">
            {isZScore ? `Judge Sigmas Above/Below Team's Valid Score™` : 'Implicit Rank by Judge'}
          </h2>
          <div className="d-flex flex-row">
            <div style={{ flex: 1 }}>
              <SigmasByJudge
                onClick={handleSigmaClick}
                isZScore={isZScore}
                judges={getScoreSigmas(teamA)}
                teamId={teamA?.team}
              />
            </div>
            <div style={{ flex: 1, margin: '0 12px 0 12px' }}>
              <SigmasByJudge
                onClick={handleSigmaClick}
                isZScore={isZScore}
                judges={getScoreSigmas(teamB)}
                teamId={teamB?.team}
              />
            </div>
            <div style={{ flex: 1 }}>
              <SigmasByJudge
                onClick={handleSigmaClick}
                isZScore={isZScore}
                judges={getScoreSigmas(teamC)}
                teamId={teamC?.team}
              />
            </div>
          </div>
          {isExpanded && (
            <>
              <h2 className="mt-4 mb-4">Team Description</h2>
              <div className="d-flex flex-row">
                {[teamA, teamB, teamC].map((team, index) => (
                  <div
                    key={index}
                    style={{
                      flex: 1,
                      marginLeft: index === 1 ? '12px' : 0,
                      marginRight: index === 1 ? '12px' : 0,
                    }}
                  >
                    {team ? (
                      team.team_description ? (
                        <p className={TableStyles.DescriptionText}>{team.team_description}</p>
                      ) : (
                        <p
                          className={cx(TableStyles.DescriptionText, TableStyles.NoDescriptionText)}
                        >
                          {t('compare_screen.no_description', 'No description for this team')}
                        </p>
                      )
                    ) : (
                      <p className={cx(TableStyles.DescriptionText, TableStyles.NoDescriptionText)}>
                        {t('compare_screen.no_team', 'No team selected')}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}

          <h2 className="mt-4 mb-4">Comments</h2>
          <div className="d-flex flex-row">
            <div className="px-2" style={{ flex: 1 }}>
              <CompareTeamsNotes phaseId={phase.id} teamId={teamA?.team} />
            </div>
            <div className="px-2" style={{ flex: 1 }}>
              <CompareTeamsNotes phaseId={phase.id} teamId={teamB?.team} />
            </div>
            <div className="px-2" style={{ flex: 1 }}>
              <CompareTeamsNotes phaseId={phase.id} teamId={teamC?.team} />
            </div>
          </div>
        </div>
      </>
    )
  );
}

const mapStateToProps = (state, ownProps) => {
  const loading = getIsLoadingEvaluations(state);
  let evaluations = getResultsForSelectedPhase(state, ownProps);
  const selectedPhaseId = getSelectedPhaseId(state, ownProps.params.event_id);
  const event = getEvent(state, ownProps.params.event_id)?.toJSON();
  const phase = getPhase(state, selectedPhaseId)?.toJSON();
  const rubric = getRubric(state, selectedPhaseId)?.toJSON();
  const phaseSummary = getPhaseSummary(state, selectedPhaseId)?.toJSON();

  evaluations = evaluations = evaluations
    .sort((a, b) => a.valid_score_valid_rank - b.valid_score_valid_rank)
    .map((ev) => {
      ev.rank_strength =
        ev.rank_strength ??
        Math.round(
          ((evaluations.length - ev.valid_score_valid_rank) / (evaluations.length - 1)) * 100,
        );
      return ev;
    });

  let teamA, teamB, teamC;
  if (evaluations?.length) {
    const [teamAId, teamBId, teamCId] = ownProps.params.evaluation_ids.split(',');
    teamA = evaluations.find((e) => e.team === teamAId);
    teamB = evaluations.find((e) => e.team === teamBId);
    teamC = evaluations.find((e) => e.team === teamCId);

    if (teamA) {
      teamA.scores = getScoresByEvaluation(state, teamA.id);
      teamA.eval_id = teamA.id;
    }
    if (teamB) {
      teamB.scores = getScoresByEvaluation(state, teamB.id);
      teamB.eval_id = teamB.id;
    }
    if (teamC) {
      teamC.scores = getScoresByEvaluation(state, teamC.id);
      teamC.eval_id = teamC.id;
    }
  }

  return {
    teamIds: ownProps.params.evaluation_ids.split(','),
    loading,
    evaluations,
    selectedPhaseId,
    event,
    phase,
    rubric,
    phaseSummary,
    teamA,
    teamB,
    teamC,
    canAccessResults: !isNavigator() || getPhaseNavigatorsViewResultsEnabled(state, ownProps),
  };
};

export default withRouter(connect(mapStateToProps, { update })(ResultsCompare));
