import { judgeNav } from '../urls';

export const displayName = 'Thank you';

export const getActionButtons = (notification) => [
  {
    title: 'Take survey',
    url: judgeNav.survey(
      notification.data.event_id,
      notification.data.phase_id,
      notification.data.judge_profile_id,
    ),
    toggleSeen: true,
  },
];

export const getText = (notification) =>
  `Thanks very much for completing all your assignments, for phase ${notification.data.phase_name} in cohort ${notification.data.cohort_name}.`;
