import { Map } from 'immutable';
import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { getUserRole } from 'components/NotesV2/helpers';
import { fetchIntros, fromIntros } from 'data/features/intros';

import Loading from 'components/Loading';
import Intro from './components/Intro';

type IntrosProps = {
  phaseId: string;
  teamId: string;
  isOrganizerScreen?: boolean;
  isTeamScreen?: boolean;
  judgeIdFilter?: string;
};

const mapDispatchToProps = {
  fetchIntros,
};

const mapStateToProps = (state: Map<string, any>, ownProps: IntrosProps) => ({
  loading: fromIntros.getIsLoading(state?.toJS()),
  intros: fromIntros.getIntros(state?.toJS(), ownProps),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const Intros = ({
  fetchIntros,
  intros,
  isOrganizerScreen,
  isTeamScreen,
  loading,
  phaseId,
  teamId,
}: IntrosProps & ConnectedProps<typeof connector>) => {
  useEffect(() => {
    fetchIntros({ phaseId, teamId, role: getUserRole(isOrganizerScreen, isTeamScreen) });
  }, [fetchIntros, phaseId, teamId, isOrganizerScreen]);

  return (
    <div className="mt-3">
      {loading && <Loading />}
      {intros.map((intro) => (
        <Intro
          key={intro.id}
          intro={intro}
          isOrganizerScreen={isOrganizerScreen}
          isTeamScreen={isTeamScreen}
        />
      ))}
    </div>
  );
};

export default connector(Intros);
