import { judgeNav } from '../urls';
import { pluralizeRegularNoun } from 'utils';

const evaluationCount = pluralizeRegularNoun('evaluation');

export const displayName = 'Judging Window Closes';

export const getActionButtons = (notification) => [
  {
    title: 'Complete evaluations',
    url: judgeNav.evaluations(notification.data.event_id, notification.data.judge_profile_id),
    toggleSeen: true,
  },
];

export const getText = (notification) =>
  `Evaluation deadline coming soon, you have ${evaluationCount(
    parseInt(notification.data.pending_evaluations_count),
  )} to complete for phase ${notification.data.phase_name} in the ${notification.event} cohort`;
