import { Channel, fromSocket, joinChannel, leaveChannel } from 'data/features/socket';
import { Map } from 'immutable';
import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

type OwnProps = {
  phaseId: string;
  onUpdate?: ({ hasStaleScores }: { hasStaleScores: boolean }) => void;
};

const mapStateToProps = (state: Map<string, any>) => {
  return {
    ready: fromSocket.getReady(state?.toJS()),
  };
};

const mapDispatchToProps = {
  joinChannel,
  leaveChannel,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PhaseBuildStatisticsListenerProps = ConnectedProps<typeof connector> & OwnProps;

const PhaseBuildStatisticsListener = ({
  ready,
  joinChannel,
  leaveChannel,
  phaseId,
  onUpdate,
}: PhaseBuildStatisticsListenerProps) => {
  const handlePhaseChannelEvent = (payload: any) => {
    if (!onUpdate) return;

    if (payload.type === 'build_statistics_done') {
      onUpdate({ hasStaleScores: false });
    }
    if (payload.type === 'has_stale_scores') {
      onUpdate({ hasStaleScores: true });
    }
  };

  useEffect(() => {
    if (!ready || !phaseId) return;
    const listener = joinChannel({
      channel: Channel.Phases,
      listener: handlePhaseChannelEvent,
      params: {
        id: phaseId,
      },
    })?.payload.id;

    return () => {
      leaveChannel({ channel: Channel.Phases, listener });
    };
  }, [ready, phaseId]);

  return null;
};

export default connector(PhaseBuildStatisticsListener);
