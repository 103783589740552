import { Form, InputGroup } from '@valid-eval/shared-react-components';
import { ChangeEvent, ChangeEventHandler, useRef } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import { required as requiredVal } from 'components/FormFields/validations';
import { mergeRefs } from 'utils';
export type TextAreaProps = {
  after?: React.ReactNode;
  before?: React.ReactNode;
  id?: string;
  label?: string;
  name: string;
  placeholder?: string;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  transform?(value: string): string;
  disabled?: boolean;
  required?: boolean;
  helpText?: string;
  className?: string;
  spellCheck?: boolean;
  rows?: number;
  autoexpand?: boolean;
};

const TextArea = ({
  after,
  before,
  id,
  label,
  name,
  placeholder,
  rules,
  transform = (value: string) => value,
  disabled,
  required,
  helpText,
  className,
  spellCheck,
  rows = 3,
  autoexpand = false,
}: TextAreaProps) => {
  const { control, formState } = useFormContext();
  const ref = useRef<HTMLTextAreaElement>(null);

  const showValidation =
    !!formState?.touchedFields?.[name] || !!formState?.dirtyFields?.[name] || formState.isSubmitted;

  const isInvalid = !!formState?.errors?.[name] && showValidation;

  const isValid = !formState?.errors?.[name] && showValidation;

  rules = rules || {};
  rules = required ? { ...rules, validate: { required: requiredVal, ...rules.validate } } : rules;

  const handleChange =
    (handler: ChangeEventHandler<HTMLTextAreaElement>) =>
    (ev: ChangeEvent<HTMLTextAreaElement>) => {
      resizeTextArea();
      handler(ev);
    };

  const resizeTextArea = () => {
    if (autoexpand && ref.current) {
      ref.current.style.height = 'auto';
      if (ref.current.scrollHeight > 92) {
        ref.current.style.height = ref.current.scrollHeight + 10 + 'px';
      }
    }
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value, ref: fieldRef, ...fieldProps } }) => {
          if (!value) resizeTextArea();

          return (
            <Form.Group className="mb-2">
              {label && (
                <Form.Label className="w-100" htmlFor={id || name}>
                  {label}
                </Form.Label>
              )}
              {helpText && (
                <p className="d-print-none text-pre-wrap">
                  <Form.Text muted>{helpText}</Form.Text>
                </p>
              )}
              <InputGroup hasValidation>
                {before}
                <Form.Control
                  id={id || name}
                  as="textarea"
                  onChange={handleChange((value) => onChange(transform(value.target.value)))}
                  placeholder={placeholder}
                  value={value}
                  isInvalid={isInvalid}
                  isValid={isValid}
                  disabled={disabled}
                  className={className}
                  spellCheck={spellCheck}
                  rows={rows}
                  {...fieldProps}
                  ref={mergeRefs([ref, fieldRef])}
                />
                {after}
                {isInvalid && (
                  <Form.Control.Feedback type="invalid">
                    {String(formState.errors[name]?.message)}
                  </Form.Control.Feedback>
                )}
              </InputGroup>
            </Form.Group>
          );
        }}
      />
    </>
  );
};

export default TextArea;
