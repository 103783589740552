import { Container } from '@valid-eval/shared-react-components';
import { Map } from 'immutable';
import { useEffect } from 'react';
// @ts-ignore
import { AlertList } from 'react-bs-notifier';
import { connect, ConnectedProps } from 'react-redux';

import { loadMultiple as loadEvents } from 'data/actions/events';
import { remove as removeNotification } from 'data/actions/notifications';
import { fromSocket, initSocket } from 'data/features/socket';
import { getCurrentUser, getCurrentUserEventIds, getNotificationsArray } from 'data/reducers';

import MainFooter from './components/MainFooter';
import MainMenu from './components/MainMenu';
import ErrorBoundary from './ErrorBoundary';
import CookiesBanner from './teams/components/CookiesBanner';

type OwnProps = {
  children: React.ReactNode;
};

const mapStateToProps = (state: Map<string, any>) => {
  const alerts = getNotificationsArray(state);
  const eventIds = getCurrentUserEventIds(state);
  const user = getCurrentUser(state)?.toJS();
  const ready = fromSocket.getReady(state?.toJS());
  return { alerts, eventIds, user, ready };
};

const mapDispatchToProps = {
  initSocket,
  loadEvents,
  removeNotification,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type WrapperProps = OwnProps & ConnectedProps<typeof connector>;

const Wrapper = ({
  alerts,
  children,
  eventIds,
  initSocket,
  loadEvents,
  removeNotification,
  user,
}: WrapperProps) => {
  // load events when eventIds change
  useEffect(() => {
    if (eventIds && !eventIds.isEmpty()) loadEvents();
  }, [eventIds]);

  // connect to socket when user is logged in
  useEffect(() => {
    if (user?.id) initSocket();
  }, [user?.id]);

  return (
    <Container fluid>
      {/* @ts-expect-error MainMenu is not typed */}
      <MainMenu />
      <AlertList alerts={alerts} onDismiss={removeNotification} />
      <div className="full-height-container">
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
      <MainFooter />
      <CookiesBanner trackingCookies={true} eventId={'general'} />
    </Container>
  );
};

export default connector(Wrapper);
